import React, { Component } from "react";
import CommonConfig from "../../utils/constant";
import api from "../../utils/apiClient";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";
import userImage from "../../assets/images/user.png";
import APIConstant from "../../utils/pathConstant";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
const initialState = {
  firstName: "",
  firstNameErr: "",
  firstNameErrText: "",

  lastName: "",
  lastNameErr: false,
  lastNameErrText: "",

  email: "",
  emailErr: false,
  emailErrText: "",

  phone: "",
  phoneErr: false,
  phoneErrText: "",

  userID: "",
  PicturePath: "",
  click: 0,
  imgSrc: "",
  image: "",
  isimageErr: "",
  isimagehelperText: "",
  loaded: false,
};
export default class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    this.getUserProfileByID();
  };

  resetScreen = () => {
    this.setState(initialState);
  };
  handleChange = (e, type) => {
    if (type == "firstName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          firstName: e.target.value,
          firstNameErr: true,
          firstNameErrText: "First name is required",
        });
      } else {
        this.setState({
          firstName: e.target.value,
          firstNameErr: false,
          firstNameErrText: "",
        });
      }
    }
    if (type == "lastName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          lastName: e.target.value,
          lastNameErr: true,
          lastNameErrText: "Last name is required",
        });
      } else {
        this.setState({
          lastName: e.target.value,
          lastNameErr: false,
          lastNameErrText: "",
        });
      }
    }
    if (type == "email") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          email: e.target.value,
          emailErr: true,
          emailErrText: "email is required",
        });
      } else {
        this.setState({
          email: e.target.value,
          emailErr: false,
          emailErrText: "",
        });
      }
    }
    if (type == "phone") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          phone: e.target.value,
          phoneErr: true,
          phoneErrText: "Mobile number is required",
        });
      } else {
        this.setState({
          phone: e.target.value,
          phoneErr: false,
          phoneErrText: "",
        });
      }
    }
    if (type == "loginID") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          loginID: e.target.value,
          loginIDErr: true,
          loginIDErrText: "Login id is required",
        });
      } else {
        this.setState({
          loginID: e.target.value,
          loginIDErr: false,
          loginIDErrText: "",
        });
      }
    }
  };

  validate = (e) => {
    let isValid = true;
    if (this.state.firstName === "" || this.state.firstName === null) {
      this.setState({
        firstNameErr: true,
        firstNameErrText: "Please enter first name ",
      });
      isValid = false;
    } else {
      this.setState({ firstNameErr: false, firstNameErrText: "" });
    }

    if (this.state.lastName === "" || this.state.lastName === null) {
      this.setState({
        lastNameErr: true,
        lastNameErrText: "Please enter last name ",
      });
      isValid = false;
    } else {
      this.setState({ lastNameErr: false, lastNameErrText: "" });
    }

    if (this.state.email === "" || this.state.email === null) {
      this.setState({ emailErr: true, emailErrText: "Please enter email " });
      isValid = false;
    } else if (!this.state.email.match(CommonConfig.RegExp.email)) {
      this.setState({ emailErr: true, emailErrText: "Email  is not valid" });
      isValid = false;
    } else {
      this.setState({ emailIDErr: false, emailIDErrText: "" });
    }

    if (this.state.phone === "" || this.state.phone === null) {
      this.setState({ phoneErr: true, phoneErrText: "Please enter phone " });
      isValid = false;
    } else if (!this.state.phone.match(CommonConfig.RegExp.phone)) {
      this.setState({ phoneErr: true, phoneErrText: "phone  is not valid" });
      isValid = false;
    } else {
      this.setState({ phoneErr: false, phoneErrText: "" });
    }

    if (this.state.loginID === "" || this.state.loginID === null) {
      this.setState({
        loginIDErr: true,
        loginIDErrText: "Please enter loginID ",
      });
      isValid = false;
    } else {
      this.setState({ loginIDErr: false, loginIDErrText: "" });
    }
    return isValid;
  };

  getUserProfileByID = async () => {
    Loader.show();
    let data = {
      UserID: CommonConfig.loginData().SecurityUserID,
    };
    try {
      api
        .post(APIConstant.path.getUserProfileByID, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            Loader.hide();
            let profileImageObject;
            let filePath = response.data[0].PicturePath;
            if (!CommonConfig.isEmpty(filePath)) {
              let convertedBase64Media =
                CommonConfig.convertImgToBase64(filePath);
              await convertedBase64Media.then(async (dataUrl) => {
                let fileData = CommonConfig.base64toFile(
                  await dataUrl,
                  CommonConfig.findFileNameFromURL(filePath)
                );
                var reader = new FileReader();
                var url = reader.readAsDataURL(fileData);
                reader.onloadend = function (e) {
                  this.setState({
                    imgSrc: [reader.result],
                  });
                }.bind(this);
                if (fileData) {
                  profileImageObject = fileData;
                }
              });
            }
            this.setState({
              image: profileImageObject,
              userID: response.data[0].SecurityUserID,
              email: response.data[0].EmailID,
              phone: response.data[0].PhoneNumber,
              lastName: response.data[0].LastName,
              firstName: response.data[0].FirstName,
            });
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };
  handleSubmit = (e) => {
    Loader.show();
    e.preventDefault();

    if (this.validate()) {
      try {
        var formData = new FormData();
        formData.append("UserID", CommonConfig.loginData().SecurityUserID);

        formData.append("EmailID", this.state.email);
        formData.append("LastName", this.state.lastName);
        formData.append("FirstName", this.state.firstName);
        formData.append("LoginID", this.state.loginID);
        formData.append("Phone", this.state.phone);
        formData.append("PicturePath", this.state.image);

        api
          .post(APIConstant.path.updateUserProfile, formData)
          .then((res) => {
            if (res.success) {
              this.props.history.push("/dashboard");
              this.setState(initialState);
              Toast.success({ message: res.message });
              this.resetScreen();
              Loader.hide();
            } else {
              Toast.error({ message: res.message });
              // Loader.hide();
            }
          })
          .catch((err) => {});
      } catch (e) {}
    }
  };

  onImageChange = (event) => {
    event.preventDefault();
    var file = event.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    if (Number(file.size) < 1024000 || Number(file.size) == 1024000) {
      this.setState({ image: file, isimageErr: false, isimagehelperText: "" });
      reader.onloadend = function (e) {
        this.setState({
          imgSrc: [reader.result],
          click: 1,
        });
      }.bind(this);
      // this.forceUpdate();
    } else {
      this.setState({ image: this.state.PicturePath });
      // cogoToast.error("Image size should not be more than 1 MB");
    }
  };
  onError = () => {
    this.setState({
      imgSrc: undefined,
    });
  };

  render() {
    const {
      lastName,
      lastNameErr,
      lastNameErrText,
      firstName,
      firstNameErr,
      firstNameErrText,
      email,
      emailErr,
      emailErrText,
      phone,
      phoneErr,
      phoneErrText,
      imgSrc,
    } = this.state;

    return (
      <div className="main-container">
        <div className="main-heading">
          <h3>Edit Profile</h3>
        </div>
        <div className="main-container-body">
          <Grid container>
            <Grid item xs={12} md={4}>
              {this.state.click === 0 ? (
                <div className="edit-image">
                  <img
                    src={CommonConfig.isEmpty(imgSrc) ? userImage : imgSrc}
                    width="150px"
                    height="150px"
                    alt="userImage"
                    onError={this.onError}
                  />

                  <label className="edit-image-label" htmlFor="edit_profile">
                    <i className="fa fa-edit"></i>
                  </label>
                  <input
                    type="file"
                    onChange={this.onImageChange}
                    style={{ visibility: "hidden" }}
                    className="filetype"
                    id="edit_profile"
                    accept="image/jpeg" /*onChange={this.onUpload}*/
                  />
                </div>
              ) : (
                <div className="edit-image">
                  <img
                    src={CommonConfig.isEmpty(imgSrc) ? userImage : imgSrc}
                    width="150px"
                    height="150px"
                    alt="userImage"
                    onError={this.onError}
                  />

                  <label htmlFor="edit_profile" className="edit-image-label">
                    <i className="fa fa-edit"></i>
                  </label>
                  <input
                    type="file"
                    onChange={this.onImageChange}
                    style={{ visibility: "hidden" }}
                    className="filetype"
                    id="edit_profile"
                    accept="image/jpeg" /*onChange={this.onUpload}*/
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <TextField
                      variant="standard"
                      id="first name"
                      label="First Name"
                      error={firstNameErr}
                      helperText={firstNameErrText}
                      type="text"
                      value={firstName}
                      onChange={(e) => this.handleChange(e, "firstName")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <TextField
                      variant="standard"
                      id="last name"
                      label="Last Name"
                      error={lastNameErr}
                      helperText={lastNameErrText}
                      type="text"
                      value={lastName}
                      onChange={(e) => this.handleChange(e, "lastName")}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <TextField
                      variant="standard"
                      id="email"
                      label="Email address"
                      error={emailErr}
                      helperText={emailErrText}
                      type="text"
                      value={email}
                      onChange={(e) => this.handleChange(e, "email")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <TextField
                      variant="standard"
                      id="phone"
                      label="Mobile number"
                      error={phoneErr}
                      helperText={phoneErrText}
                      type="text"
                      value={phone}
                      onChange={(e) => this.handleChange(e, "phone")}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="action-footer">
                <button
                  className="secondary-btn"
                  onClick={(e) => this.resetScreen()}
                >
                  Reset
                </button>
                <button
                  className="primary-btn"
                  onClick={(e) => this.handleSubmit(e)}
                >
                  Update
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
