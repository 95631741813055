import React, { Component } from "react";
import CommonConfig from "../../utils/constant";
import api from "../../utils/apiClient";
import APIConstant from "../../utils/pathConstant";
import Loader from "../Shared/Loader/Loader";
import Toast from "../Shared/Toast/Toast";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";

const initialState = {
  securityUserID: "",
  role: "",
  menuList: [],
  userType: "",
};

export class UserAccess extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
    // await this.getMenuMaster();
    await this.getUserMenuByID();
  };

  resetScreen = () => {
    this.setState(initialState);
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
    // this.getMenuMaster();
  };

  getMenuMaster = async () => {
    Loader.show();
    try {
      await api
        .post(APIConstant.path.getMenuMaster)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({
              menuList: response.data,
            });

            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  handleOpen = (item, type) => { };

  handleClose = (type) => { };

  handleChange = (e, idx, type) => {
    if (type === "UserType") {
      this.setState({ userType: e.target.value });
    } else if (type === "AddAccess") {
      let menuList = this.state.menuList;
      menuList[idx]["AddAccess"] = e.target.checked ? 1 : 0;
      this.setState({ menuList: menuList });
    } else if (type === "EditAccess") {
      let menuList = this.state.menuList;
      menuList[idx]["EditAccess"] = e.target.checked ? 1 : 0;
      this.setState({ menuList: menuList });
    } else if (type === "ViewAccess") {
      let menuList = this.state.menuList;
      menuList[idx]["ViewAccess"] = e.target.checked ? 1 : 0;
      this.setState({ menuList: menuList });
    } else if (type === "DeleteAccess") {
      let menuList = this.state.menuList;
      menuList[idx]["DeleteAccess"] = e.target.checked ? 1 : 0;
      this.setState({ menuList: menuList });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    Loader.show();
    try {
      let data = {
        SecurityUserID: CommonConfig.loginData().SecurityUserID,
        AccessList: this.state.menuList,
        LoginUserID: this.props.history.location.state.UserID

      }
      await api.post(APIConstant.path.addUpdateUserAccess, data).then(async (response) => {
        let res = await response;
        if (res.success) {
          Loader.hide();
          this.props.history.push('/user')
          Toast.success({ message: res.message });
        }
        else {
          Loader.hide();
          Toast.error({ message: res.message });
        }
      }).catch(error => {
        Loader.hide();
        Toast.error({ message: error });
      })
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  }

  getUserMenuByID = () => {
    let data = {
      SecurityUserID: this.props.history.location.state.UserID,
    }
    try {
      api.post(APIConstant.path.getUserMenuByID, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ menuList: response.data })
          } else {

          }
        })
        .catch((err) => {
          Toast.error({ message: err });
        })
    } catch (err) {
      Toast.error({ message: err });
    }
  }
  validate = () => { };

  render() {
    const { menuList } = this.state;

    return (
      <div className="main-container">
        <div className="main-header">
          <div className="title-action-row">
            <h3>Users Access</h3>
          </div>
        </div>
        {menuList.length ? (
          menuList.map((menu, idx) => {
            return (
              <div>
                <div key={idx}>
                  <Grid container spacing={2}>
                    <div className="dialog-content">{menu.MenuName}</div>
                    <div className="dialog-content1">
                      <FormGroup >

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={menu.AddAccess == 1 ? true : false}
                              onChange={(e) =>
                                this.handleChange(e, idx, "AddAccess")
                              }
                              name="AddAccess"
                            />
                          }
                          label="Add"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={menu.EditAccess == 1 ? true : false}
                              onChange={(e) =>
                                this.handleChange(e, idx, "EditAccess")
                              }
                              name="EditAccess"
                            />
                          }
                          label="Edit"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={menu.ViewAccess == 1 ? true : false}
                              onChange={(e) =>
                                this.handleChange(e, idx, "ViewAccess")
                              }
                              name="ViewAccess"
                            />
                          }
                          label="View"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={menu.DeleteAccess == 1 ? true : false}
                              onChange={(e) =>
                                this.handleChange(e, idx, "DeleteAccess")
                              }
                              name="DeleteAccess"
                            />
                          }
                          label="Delete"
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <span>No record found</span>
          </div>
        )}
        <Button
          className="primary-btn"
          onClick={(e) => this.handleSubmit(e)}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default UserAccess;
