import React, { Component } from "react";
import { Link } from "react-router-dom";
import logoName from "../../assets/images/brandName.png";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstant";
import Toast from "../Shared/Toast/Toast";
import api from "../../utils/apiClient";

const initialState = {
  IsCollapseActive: false,
  menus: [],
  topSideHead: 0,
  role: "",
};

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
    // await this.getUserMenu();
  };

  sideBarClose = () => {
    document.getElementById("sidebar").classList.toggle("close-sidebar");
  };

  getUserMenu = () => {
    let data = {
      SecurityUserID: CommonConfig.loginData().SecurityUserID,
    }
    try {
      api.post(APIConstant.path.getUserMenu, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ menus: response.data })
            localStorage.setItem('userModuleAccess', JSON.stringify(response.data))
          } else {

          }
        })
        .catch((err) => {
          Toast.error({ message: err });
        })
    } catch (err) {
      Toast.error({ message: err });
    }
  }

  render() {
    const { menus } = this.state;
    return (
      <div id="sidebar" className="sidebar-container">

        <div className="sidebar-header">

          <div className="logo-container">
            <img src={logoName} alt="Logo" />
          </div>
          <div
            id="closeButton"
            onClick={() => this.sideBarClose()}
            className="close-button d-lg-none"
          >
            <i className="fa fa-times text-white"></i>
          </div>
        </div>
        <div className="sidebar-body">
          <div className="sidebar-content-header">
            <span className="header-text">Dashboards</span>
          </div>
          {/* {menus.map((menuItem) => {

            return (
              <div className="sidebar-content">
                <li className="tabs-list">
                  <Link to={menuItem.Path}>{menuItem.MenuName}</Link>
                </li>
              </div>
            )

          })} */}

          <div className="sidebar-content">
            <ul className="tabs-container">
              <li className="tabs-list">
                <Link to="/task">Task</Link>
              </li>
              <li className="tabs-list">
                <Link to="/project">Projects</Link>
              </li>
              <li className="tabs-list">
                <Link to="/leave">Leave</Link>
              </li>
              {this.state.role === "Project Manager" ||
                this.state.role === "CEO" ||
                this.state.role === "HR" ? (
                <li className="tabs-list">
                  <Link to="/User">Users</Link>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
        {/* <div className="sidebar-footer"></div> */}
      </div>
    );
  }
}
