import React from 'react';
import { Route } from "react-router-dom";

const RegularRoute = ({
    component: Component,
    meta,
    ...otherProps
}) => {

    React.useEffect(() => {
        document.title = meta.title;
    }, [meta])

    const masterLayout = (props) => (
        <div id="main-section" className="main-section">
            <Component {...props} />
        </div>
    )

    return (
        <Route
            {...otherProps}
            render={(props) => masterLayout(props)}
        />
    )
}

export default RegularRoute;
