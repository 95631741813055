import { apiBase } from "./config";
const SERVER_PATH = apiBase;
export default class APIConstant {
  static path = {
    // Auth API
    login: `${SERVER_PATH}/authentication/login`,
    registerUser: `${SERVER_PATH}/authentication/registerUser`,
    changePassword: `${SERVER_PATH}/authentication/changePassword`,
    forgotPassword: `${SERVER_PATH}/authentication/forgotPassword`,
    resetPassword: `${SERVER_PATH}/authentication/resetPassword`,
    checkResetPasswordToken: `${SERVER_PATH}/authentication/checkResetPasswordToken`,
    userChangePassword: `${SERVER_PATH}/authentication/userChangePassword`,

    // Task API

    addUpdateTask: `${SERVER_PATH}/task/addUpdateTask`,
    getTask: `${SERVER_PATH}/task/getTask`,
    deleteTask: `${SERVER_PATH}/task/deleteTask`,
    getTaskDetailByID: `${SERVER_PATH}/task/getTaskDetailByID`,
    getStringMap: `${SERVER_PATH}/task/getStringMap`,
    getAssigneeList: `${SERVER_PATH}/task/getAssigneeList`,
    getObserverList: `${SERVER_PATH}/task/getObserverList`,
    getTimeSheet: `${SERVER_PATH}/task/getTimeSheet`,
    getClientTimeSheet: `${SERVER_PATH}/task/getClientTimeSheet`,

    // Project API
    addUpdateProject: `${SERVER_PATH}/project/addUpdateProject`,
    getProject: `${SERVER_PATH}/project/getProject`,
    getProjectByID: `${SERVER_PATH}/project/getProjectByID`,
    deleteProject: `${SERVER_PATH}/project/deleteProject`,
    getProjectDropDown: `${SERVER_PATH}/project/getProjectDropDown`,
    getProjectManagerDropDown: `${SERVER_PATH}/project/getProjectManagerDropDown`,

    //User Profile API

    getUserProfileByID: `${SERVER_PATH}/userprofile/getUserProfileByID`,
    updateUserProfile: `${SERVER_PATH}/userprofile/updateUserProfile`,
    getUserRole: `${SERVER_PATH}/userprofile/getUserRole`,
    getUserList: `${SERVER_PATH}/userprofile/getUserList`,
    getUserListByID: `${SERVER_PATH}/userprofile/getUserListByID`,
    deleteUser: `${SERVER_PATH}/userprofile/deleteUser`,
    getUserByID: `${SERVER_PATH}/userprofile/getUserByID`,
    updateUser: `${SERVER_PATH}/userprofile/updateUser`,

    //Leave API
    addUpdateLeave: `${SERVER_PATH}/leave/addUpdateLeave`,
    getLeaveByID: `${SERVER_PATH}/leave/getLeaveByID`,
    deleteLeave: `${SERVER_PATH}/leave/deleteLeave`,
    getLeave: `${SERVER_PATH}/leave/getLeave`,

    //Manage Leave API
    getManageLeave: `${SERVER_PATH}/manageleave/getManageLeave`,
    getManageLeaveByID: `${SERVER_PATH}/manageleave/getManageLeaveByID`,
    updateLeaveStatus: `${SERVER_PATH}/manageleave/updateLeaveStatus`,

    //User Access API
    getMenuMaster: `${SERVER_PATH}/useraccess/getMenuMaster`,
    addUpdateUserAccess: `${SERVER_PATH}/useraccess/addUpdateUserAccess`,
    getUserMenu: `${SERVER_PATH}/useraccess/getUserMenu`,
    getUserMenuByID: `${SERVER_PATH}/useraccess/getUserMenuByID`,

  };
}
