import React, { Component } from "react";
import CommonConfig from "../../utils/constant";
import api from "../../utils/apiClient";
import APIConstant from "../../utils/pathConstant";
import { MenuItem, FormControl, Select } from "@mui/material/";
import Loader from "../Shared/Loader/Loader";
import Toast from "../Shared/Toast/Toast";
import mainSvg from "../../assets/images/Register.svg";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import eqLogo from "../../assets/images/EquatorLogo.png.png";
import fbSvg from "../../assets/icons/facebook icon.png.png";
import twitterSvg from "../../assets/icons/twitter icon.png.png";
import githubSvg from "../../assets/icons/github icon.png.png";
import googleSvg from "../../assets/icons/google icon.png.png";
const initialState = {
  userRoleList: [],
  firstName: "",
  firstNameErr: false,
  firstNameErrText: "",
  LastName: "",
  lastNameErr: false,
  lastNameErrText: "",
  password: "",
  passwordErr: false,
  passwordErrText: "",
  emailId: "",
  emailIdErr: false,
  emailIdErrText: "",
  userType: "",
  userTypeErr: false,
  userTypeErrText: "",
  values: "",
  showPassword: false,
};

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.getUserRole();
  }

  getUserRole = async () => {
    try {
      await api
        .post(APIConstant.path.getUserRole)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            const itemToBeRemoved = response.data.filter(
              (item) => item.label === "CEO"
            );
            response.data.splice(
              response.data.findIndex(
                (a) => a.label === itemToBeRemoved[0].label
              ),
              1
            );

            this.setState({ userRoleList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {});
    } catch (err) {
      Loader.hide();
    }
  };

  handleChange = (e, type) => {
    if (type === "firstName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          firstName: "",
          firstNameErr: true,
          firstNameErrText: "Please enter first name.",
        });
      } else {
        this.setState({
          firstName: e.target.value,
          firstNameErr: false,
          firstNameErrText: "",
        });
      }
    } else if (type === "lastName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          lastName: "",
          lastNameErr: true,
          lastNameErrText: "Please enter last name.",
        });
      } else {
        this.setState({
          lastName: e.target.value,
          lastNameErr: false,
          lastNameErrText: "",
        });
      }
    } else if (type === "password") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          password: "",
          passwordErr: true,
          passwordErrText: "Please enter password.",
        });
      } else {
        this.setState({
          password: e.target.value,
          passwordErr: false,
          passwordErrText: "",
        });
      }
    } else if (type === "emailId") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          emailId: "",
          emailIdErr: true,
          emailIdErrText: "Please enter email.",
        });
      } else {
        this.setState({
          emailId: e.target.value,
          emailIdErr: false,
          emailIdErrText: "",
        });
      }
    } else if (type === "userType") {
      console.log(e.target.value, e);
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          userType: "",
          userTypeErr: true,
          userTypeErrText: "Please select user type.",
        });
      } else {
        this.setState({
          userType: e.target.value,
          userTypeErr: false,
          userTypeErrText: "",
        });
      }
    }
  };

  validate = () => {
    const { firstName, lastName, emailId, password, userType } = this.state;
    let IsValid = true;
    if (CommonConfig.isEmpty(firstName)) {
      this.setState({
        firstNameErr: true,
        firstNameErrText: "First name is required",
      });
      IsValid = false;
    } else {
      this.setState({ firstNameErr: false, firstNameErrText: "" });
    }
    if (CommonConfig.isEmpty(lastName)) {
      this.setState({
        lastNameErr: true,
        lastNameErrText: "Last name is required",
      });
      IsValid = false;
    } else {
      this.setState({ lastNameErr: false, lastNameErrText: "" });
    }
    if (CommonConfig.isEmpty(password)) {
      this.setState({
        passwordErr: true,
        passwordErrText: "Password is required",
      });
      IsValid = false;
    } else {
      this.setState({ passwordErr: false, passwordErrText: "" });
    }
    if (CommonConfig.isEmpty(emailId)) {
      this.setState({ emailIdErr: true, emailIdErrText: "Email is required" });
      IsValid = false;
    } else {
      this.setState({ emailIdErr: false, emailIdErrText: "" });
    }
    if (CommonConfig.isEmpty(userType)) {
      this.setState({
        userTypeErr: true,
        userTypeErrText: "User type is required",
      });
      IsValid = false;
    } else {
      this.setState({ userTypeErr: false, userTypeErrText: "" });
    }
    return IsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      Loader.show();
      const { firstName, lastName, emailId, password, userType } = this.state;
      let data = {
        FirstName: firstName,
        LastName: lastName,
        EmailId: emailId,
        Password: password,
        UserType: userType,
      };
      try {
        api
          .post(APIConstant.path.registerUser, data)
          .then((res) => {
            if (res.success) {
              Loader.hide();
              Toast.success({ message: res.message });
              this.setState(initialState);
              this.props.history.push("/");
            } else {
              Loader.hide();
              Toast.error({ message: res.message });
              this.setState(initialState);
            }
          })
          .catch((err) => {
            Loader.hide();
            Toast.error({ message: err });
          });
      } catch (err) {
        Loader.hide();
        Toast.error({ message: err });
      }
    }
  };
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };
  render() {
    const {
      userRoleList,
      firstName,
      firstNameErr,
      firstNameErrText,
      lastName,
      lastNameErr,
      lastNameErrText,
      emailId,
      emailIdErr,
      emailIdErrText,
      userType,
      userTypeErr,
      userTypeErrText,
      password,
      passwordErr,
      passwordErrText,
      showPassword,
    } = this.state;
    return (
      <div className="main-auth-section">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div>
              <h1>Join Equator</h1>
            </div>
            <div>
              <p>Let’s get your account setup</p>
            </div>
            <div>
              <form className="form">
                <div className="icon">
                  <div className="input-icon">
                    <TextField
                      required
                      className="w-100"
                      label="First Name"
                      value={firstName}
                      onChange={(e) => this.handleChange(e, "firstName")}
                      name="firstName"
                      error={firstNameErr}
                      helperText={firstNameErrText}
                    />
                  </div>
                </div>
                <br />
                <div className="icon">
                  <div className="input-icon">
                    <TextField
                      required
                      className="w-100"
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => this.handleChange(e, "lastName")}
                      name="lastName"
                      error={lastNameErr}
                      helperText={lastNameErrText}
                    />
                  </div>
                </div>
                <br />
                <div className="icon">
                  <div className="input-icon">
                    <TextField
                      className="w-100 mt-4"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      variant="standard"
                      onChange={(e) => this.handleChange(e, "password")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(e) => this.handleClickShowPassword()}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="Password"
                      error={passwordErr}
                      helperText={passwordErrText}
                    />
                  </div>
                </div>
                <br />
                <div className="icon">
                  <div className="input-icon">
                    <TextField
                      required
                      className="w-100"
                      label="Email"
                      onChange={(e) => this.handleChange(e, "emailId")}
                      value={emailId}
                      name="emailId"
                      error={emailIdErr}
                      helperText={emailIdErrText}
                    />
                  </div>
                </div>
                <br />
                <div className="icon">
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120 }}
                    className="input-icon"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Select User Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={userType}
                      onChange={(e) => this.handleChange(e, "userType")}
                      error={userTypeErr}
                      helperText={userTypeErrText}
                      label="Select User Role"
                    >
                      {userRoleList.map((p) => (
                        <MenuItem key={p.name + p.value} value={p.value}>
                          {p.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <button
                  type="submit"
                  id="submit"
                  value="Create Account"
                  onClick={(e) => this.handleSubmit(e)}
                >
                  Create Account
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="image-box">
              <img src={mainSvg} />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-2 col-sm-12">
            <div className="logo-box">
              <img src={eqLogo} />
            </div>
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="or d-flex justify-content-center">
              <hr />
              <label>or</label>
              <hr />
            </div>
            <div className="logo">
              <div className="facebook-icon">
                <img src={fbSvg} />
              </div>
              <div className="twitter-icon">
                <img src={twitterSvg} />
              </div>
              <div className="github-icon">
                <img src={githubSvg} />
              </div>
              <div className="google-icon">
                <img src={googleSvg} />
              </div>
            </div>
            <div className="creat-account">
              <a href="">
                {" "}
                Already a member ? &nbsp;{" "}
                <span
                  href="javascript:;"
                  onClick={() => this.props.history.push("/login")}
                >
                  Log in
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
