import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import CommonConfig from "../../utils/constant";
import api from "../../utils/apiClient";
import APIConstant from "../../utils/pathConstant";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import eqLogo from "../../assets/images/EquatorLogo.png.png";
import mainSvg from "../../assets/images/Group 46.png.png";
import fbSvg from "../../assets/icons/facebook icon.png.png";
import twitterSvg from "../../assets/icons/twitter icon.png.png";
import githubSvg from "../../assets/icons/github icon.png.png";
import googleSvg from "../../assets/icons/google icon.png.png";

const initialState = {
  userName: "",
  userNameErr: false,
  userNameErrText: "",
  password: "",
  passwordErr: false,
  passwordErrText: "",
  showPassword: false,
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount = async () => { };

  handleChange = (e, type) => {
    if (type === "username") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          userName: "",
          userNameErr: true,
          userNameErrText: "Please enter email id",
        });
      } else {
        this.setState({
          userName: e.target.value,
          userNameErr: false,
          userNameErrText: "",
        });
      }
    }
    if (type === "password") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          password: "",
          passwordErr: true,
          passwordErrText: "Please enter password",
        });
      } else {
        this.setState({
          password: e.target.value,
          passwordErr: false,
          passwordErrText: "",
        });
      }
    }
  };

  validate = () => {
    const { userName, password } = this.state;
    let IsValid = true;
    if (CommonConfig.isEmpty(userName)) {
      this.setState({
        userNameErr: true,
        userNameErrText: "Email is required",
      });
      IsValid = false;
    } else {
      this.setState({ userNameErr: false, userNameErrText: "" });
    }
    if (CommonConfig.isEmpty(password)) {
      this.setState({
        passwordErr: true,
        passwordErrText: "Password is required",
      });
      IsValid = false;
    } else {
      this.setState({ passwordErr: false, passwordErrText: "" });
    }
    return IsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      Loader.show();
      const { userName, password } = this.state;
      let data = {
        Username: userName,
        Password: password,
      };
      try {
        api
          .post(APIConstant.path.login, data)
          .then((res) => {
            if (res.success) {
              localStorage.setItem("loginData", JSON.stringify(res.data));
              localStorage.setItem("isAuthorized", res.success);
              this.props.history.push("/task");
              Loader.hide();
              Toast.success({ message: res.message });
              this.getUserMenuByID(CommonConfig.loginData().SecurityUserID);
            } else {
              Loader.hide();
              Toast.error({ message: res.message });
              this.setState({ password: "" });
              this.setState({ userName: "" });
            }
          })
          .catch((err) => {
            Loader.hide();
            Toast.error({ message: err });
          });
      } catch (err) {
        Loader.hide();
        Toast.error({ message: err });
      }
    }
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  getUserMenuByID = async (ID) => {
    let data = {
      SecurityUserID: ID,
    }
    try {
      await api.post(APIConstant.path.getUserMenuByID, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            // let data = Object.assign({}, response.data);
            localStorage.setItem("UserAccess", JSON.stringify(response.data));

          } else {

          }
        })
        .catch((err) => {
          Toast.error({ message: err });
        })
    } catch (err) {
      Toast.error({ message: err });
    }
  }
  render() {
    const {
      userName,
      userNameErr,
      userNameErrText,
      password,
      passwordErr,
      passwordErrText,
      showPassword,
    } = this.state;
    return (
      <div className="main-auth-section">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div>
              <h1>Welcome to Equator</h1>
            </div>
            <div>
              <p>
                Please sign-in to your account and
                <br />
                start the adventure
              </p>
            </div>
            <div>
              <form action="/action_page.php" className="form">
                <div className="email-password">
                  <div className="icon">
                    <div className="input-icon">
                      <TextField
                        className="w-100"
                        required
                        type="text"
                        variant="standard"
                        label="Email"
                        name="userName"
                        value={userName}
                        onChange={(e) => this.handleChange(e, "username")}
                        error={userNameErr}
                        helperText={userNameErrText}
                      />
                    </div>
                  </div>
                  <div className="icon">
                    <div className="input-icon">
                      <TextField
                        className="w-100 mt-4"
                        variant="standard"
                        required
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => this.handleChange(e, "password")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) => this.handleClickShowPassword()}
                                //   onMouseDown={(e) => this.handleMouseDownPassword()}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        label="Password"
                        error={passwordErr}
                        helperText={passwordErrText}
                      />
                    </div>
                  </div>
                </div>
                <div className="forgot-password">
                  <a
                    href="javascript:;"
                    onClick={() => this.props.history.push("/forgot-password")}
                  >
                    Forgot Password ?
                  </a>
                </div>
                <button
                  type="submit"
                  value="LOG IN"
                  id="submit"
                  onClick={(e) => this.handleSubmit(e)}
                >
                  LOG IN
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="image-box">
              <img src={mainSvg} />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-2 col-sm-12">
            <div className="logo-box">
              <img src={eqLogo} />
            </div>
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="or d-flex justify-content-center">
              <hr />
              <label>or</label>
              <hr />
            </div>
            <div className="logo">
              <div className="facebook-icon">
                <img src={fbSvg} />
              </div>
              <div className="twitter-icon">
                <img src={twitterSvg} />
              </div>
              <div className="github-icon">
                <img src={githubSvg} />
              </div>
              <div className="google-icon">
                <img src={googleSvg} />
              </div>
            </div>
            <div className="creat-account">
              <a href="">
                {" "}
                New on our platform ? &nbsp;{" "}
                <span
                  href="javascript:;"
                  onClick={() => this.props.history.push("/sign-up")}
                >
                  Create account
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
