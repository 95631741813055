import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataGrid } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CommonConfig from "../../utils/constant";
import api from "../../utils/apiClient";
import APIConstant from "../../utils/pathConstant";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
} from "@mui/material/";
import Loader from "../Shared/Loader/Loader";
import Toast from "../Shared/Toast/Toast";
import moment from "moment";
import Grid from "@mui/material/Grid";
import { TextareaAutosize } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import ReactQuill from "react-quill";

const initialState = {
  securityUserID: "",
  role: "",
  projectID: "",
  projectMasterID: "",
  projectList: [],
  projectMangerList: [],
  visibleRight: false,
  projectName: "",
  projectNameErr: false,
  projectNameErrText: "",

  clientName: "",
  clientNameErr: false,
  clientNameErrText: "",

  startDate: null,
  startDateErr: false,
  startDateErrText: "",

  endDate: null,
  endDateErr: false,
  endDateErrText: "",

  projectManager: "",
  projectManagerErr: false,
  projectManagerErrText: "",

  description: "",
  descriptionErr: false,
  descriptionErrText: "",
  openModel: false,
  openDialogBox: false,
  showModel: false,
  projectDataList: [],
  isEdit: false,
  formats: [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ],
  modules: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
  },
};

export class Project extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
    await this.getProject();
    await this.getProjectManagerDropDown();
  };

  resetScreen = () => {
    this.setState(initialState);
    this.getProject();
    this.getProjectManagerDropDown();
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
  };

  validate = () => {
    const { projectManager, projectName, clientName, startDate, endDate } =
      this.state;
    let isValid = true;

    if (CommonConfig.isEmpty(projectName)) {
      this.setState({
        projectNameErr: true,
        projectNameErrText: "Please enter project name",
      });
      isValid = false;
    } else {
      this.setState({ projectNameErr: false, projectNameErrText: "" });
    }
    if (CommonConfig.isEmpty(projectManager)) {
      isValid = false;
      this.setState({
        projectManagerErr: true,
        projectManagerErrText: "Please enter project manager name",
      });
    } else {
      this.setState({ projectManagerErr: false, projectManagerErrText: "" });
    }
    if (CommonConfig.isEmpty(startDate)) {
      isValid = false;
      this.setState({
        startDateErr: true,
        startDateErrText: "Please select start date",
      });
    } else {
      this.setState({ startDateErr: false, startDateErrText: "" });
    }
    if (CommonConfig.isEmpty(clientName)) {
      isValid = false;
      this.setState({
        clientNameErr: true,
        clientNameErrText: "Please enter client name",
      });
    } else {
      this.setState({ clientNameErr: false, clientNameErrText: "" });
    }
    return isValid;
  };

  handleChange = (e, type) => {
    if (type === "projectName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          projectName: "",
          projectNameErr: true,
          projectNameErrText: "Please enter project name",
        });
      } else {
        this.setState({
          projectName: e.target.value,
          projectNameErr: false,
          projectNameErrText: "",
        });
      }
    }
    if (type === "projectManager") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          projectManager: "",
          projectManagerErr: true,
          projectManagerErrText: "Please enter manager name",
        });
      } else {
        this.setState({
          projectManager: e.target.value,
          projectManagerErr: false,
          projectManagerErrText: "",
        });
      }
    }
    if (type === "clientName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          clientName: "",
          clientNameErr: true,
          clientNameErrText: "Please enter client name",
        });
      } else {
        this.setState({
          clientName: e.target.value,
          clientNameErr: false,
          clientNameErrText: "",
        });
      }
    }
    if (type == "startDate") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          startDate: "",
          startDateErr: true,
          startDateErrText: "Please select start date",
        });
      } else {
        this.setState({
          startDate: e,
          startDateErr: false,
          startDateErrText: "",
        });
      }
    }
    if (type == "endDate") {
      if (e) {
        this.setState({ endDate: e, endDateErr: false, endDateErrText: "" });
      }
    }
    if (type === "description") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          description: "",
          descriptionErr: true,
          descriptionErrText: "Please enter description",
        });
      } else {
        this.setState({
          description: e,
          descriptionErr: false,
          descriptionErrText: "",
        });
      }
    }
  };

  getProject = async () => {
    Loader.show();
    try {
      let data = {
        UserID: CommonConfig.loginData().SecurityUserID,
      };
      await api
        .post(APIConstant.path.getProject, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ projectList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getProjectManagerDropDown = async () => {
    try {
      await api
        .post(APIConstant.path.getProjectManagerDropDown)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ projectMangerList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  addUpdateProject = (e) => {
    e.preventDefault();

    if (this.validate()) {
      const {
        securityUserID,
        projectManager,
        projectName,
        startDate,
        endDate,
        clientName,
        description,
        projectMasterID,
      } = this.state;

      try {
        let data = {
          SecurityUserID: securityUserID,
          ProjectID: projectMasterID,
          ProjectName: projectName,
          ProjectManagerID: projectManager,
          StartDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          EndDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
          ClientID: clientName,
          Description: description,
        };
        api
          .post(APIConstant.path.addUpdateProject, data)
          .then((res) => {
            if (res.success) {
              this.resetScreen();
              Loader.hide();
              Toast.success({ message: res.message });
            } else {
              this.resetScreen();
              Loader.hide();
              Toast.error({ message: res.message });
            }
          })
          .catch((err) => {
            Loader.hide();
            Toast.error({ message: err });
          });
      } catch (err) {
        Loader.hide();
        Toast.error({ message: err });
      }
    }
  };

  getProjectByID = (projectMasterID) => {
    Loader.show();
    try {
      let data = {
        ProjectID: projectMasterID,
      };
      api
        .post(APIConstant.path.getProjectByID, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({
              projectDataList: response.data[0],
              projectMasterID: response.data[0].ProjectMasterID,
              projectID: response.data[0].ProjectID,
              projectManager: response.data[0].ProjectManagerID,
              clientName: response.data[0].ClientID,
              projectName: response.data[0].ProjectName,
              startDate: new Date(response.data[0].ProjectStartDate),
              endDate: new Date(response.data[0].ProjectEndDate),
              description: response.data[0].Description,
            });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  deleteProject = () => {
    Loader.show();
    try {
      let data = {
        SecurityUserID: this.state.securityUserID,
        ProjectID: this.state.projectMasterID,
      };
      api
        .post(APIConstant.path.deleteProject, data)
        .then((res) => {
          if (res.success) {
            this.handleClose("deleteProject");
            this.getProject();
            Loader.hide();
            Toast.success({ message: res.message });
          } else {
            this.handleClose("deleteProject");
            Loader.hide();
            Toast.error({ message: res.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  handleOpen = (item, type) => {
    if (type === "sidebar") {
      this.setState({ visibleRight: true, openModel: true });
    } else if (type === "viewProject") {
      this.setState({ showModel: true });
      this.getProjectByID(item.ProjectMasterID);
    } else if (type === "deleteProject") {
      this.setState({
        openDialogBox: true,
        projectMasterID: item.ProjectMasterID,
      });
    } else if (type === "editProject") {
      this.setState({ isEdit: true, openModel: true });
      this.getProjectByID(item.ProjectMasterID);
    }
  };

  handleClose = (type) => {
    if (type === "sidebar") {
      this.resetScreen();
      this.setState({ openModel: false });
    } else if (type === "viewProject") {
      this.setState({ showModel: false, projectMasterID: "" });
      this.resetScreen();
    } else if (type === "deleteProject") {
      this.setState({
        openDialogBox: false,
        projectMasterID: "",
      });
    }
  };

  actionBodyTemplate = () => {
    return [
      { field: "ProjectID", headerName: "Project ID", width: 150 },
      { field: "ProjectName", headerName: "Project Name", width: 150 },
      { field: "ClientID", headerName: "Client Name", width: 150 },
      { field: "ProjectStartDate", headerName: "Start Date", width: 150 },
      { field: "ProjectEndDate", headerName: "End Date", width: 150 },
      { field: "ProjectManager", headerName: "Project Manager", width: 150 },
      {
        field: "Actions",
        headerName: "Actions",
        type: "actions",
        width: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            onClick={(e) =>
              this.handleOpen(
                { ProjectMasterID: params.row.ProjectMasterID },
                "viewProject"
              )
            }
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={(e) =>
              this.handleOpen(
                { ProjectMasterID: params.row.ProjectMasterID },
                "editProject"
              )
            }
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={(e) => this.handleOpen(params.row, "deleteProject")}
          />,
        ],
      },
    ];
  };

  render() {
    const {
      openDialogBox,
      role,
      projectManager,
      projectManagerErr,
      projectManagerErrText,
      projectName,
      projectNameErrText,
      projectNameErr,
      startDate,
      startDateErr,
      startDateErrText,
      endDate,
      endDateErr,
      endDateErrText,
      description,
      descriptionErr,
      descriptionErrText,
      clientName,
      clientNameErr,
      clientNameErrText,
      projectList,
      projectMangerList,
      projectDataList,
      showModel,
      isEdit,
      openModel,
      modules,
      formats,
    } = this.state;

    return (
      <div className="main-container">
        <div className="main-container-header">
          <div>Project</div>
          {role === "Project Manager" || role === "CEO" ? (
            <div
              className="primary-btn"
              onClick={(e) => this.handleOpen(e, "sidebar")}
            >
              Add Project
            </div>
          ) : null}
        </div>
        <div className="main-container-body">
          <DataGrid
            rows={projectList}
            columns={this.actionBodyTemplate()}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.ProjectID}
            hideFooterSelectedRowCount
          />
        </div>

        <Drawer
          PaperProps={{
            sx: { width: "30%" },
          }}
          open={openModel}
          anchor="right"
        >
          <div className="main-drawer-area">
            <div className="drawer-header">
              <div>{isEdit ? "Edit Project" : "Add Project"}</div>

              <div
                className="close-btn"
                onClick={(e) => this.handleClose("sidebar")}
              >
                {"X"}
                <i className="icon-close"></i>
              </div>
            </div>
            <div className="drawer-body">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <TextField
                      variant="outlined"
                      type="projectName"
                      name="projectName"
                      value={projectName}
                      onChange={(e) => this.handleChange(e, "projectName")}
                      label="Project Name"
                    />
                    {projectNameErr
                      ? CommonConfig.showErrorMsg(projectNameErrText)
                      : null}
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <TextField
                        label="Client Name"
                        variant="outlined"
                        // type="clientName"
                        name="clientName"
                        value={clientName}
                        onChange={(e) => this.handleChange(e, "clientName")}
                      />
                      {clientNameErr
                        ? CommonConfig.showErrorMsg(clientNameErrText)
                        : null}
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            label="Start Date"
                            inputFormat="dd/MM/yyyy"
                            value={startDate}
                            onChange={(e) => this.handleChange(e, "startDate")}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                        {startDateErr
                          ? CommonConfig.showErrorMsg(startDateErrText)
                          : null}
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            label="End Date"
                            inputFormat="dd/MM/yyyy"
                            value={endDate}
                            minDate={new Date(startDate)}
                            onChange={(e) => this.handleChange(e, "endDate")}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                        {endDateErr
                          ? CommonConfig.showErrorMsg(endDateErrText)
                          : null}
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <InputLabel id="projectManager">
                        Select Project Manager
                      </InputLabel>
                      <Select
                        label="Select Project Manager"
                        value={projectManager}
                        onChange={(e) => this.handleChange(e, "projectManager")}
                      >
                        <MenuItem value="None">
                          <em>None</em>
                        </MenuItem>
                        {projectMangerList.map((p) => (
                          <MenuItem key={p.label + p.value} value={p.value}>
                            {p.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {projectManagerErr
                        ? CommonConfig.showErrorMsg(projectManagerErrText)
                        : null}
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      value={description}
                      onChange={(e) => this.handleChange(e, "description")}
                    ></ReactQuill>
                    {descriptionErr
                      ? CommonConfig.showErrorMsg(descriptionErrText)
                      : null}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="drawer-footer">
              <div
                className="secondary-btn"
                onClick={(e) => this.handleClose("sidebar")}
              >
                Cancel
              </div>
              <div
                className="primary-btn"
                onClick={(e) => this.addUpdateProject(e)}
              >
                Submit
              </div>
            </div>
          </div>
        </Drawer>

        <Dialog
          open={openDialogBox}
          onClose={(e) => this.handleClose("deleteProject")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h4 className="dialog-title">{"Delete Project"}</h4>
            <IconButton
              aria-label="close"
              onClick={(e) => this.handleClose("deleteProject")}
              sx={{
                position: "absolute",
                right: -10,
                top: 15,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {"Are you sure you want to delete this Project?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={(e) => this.handleClose("deleteProject")}>
              Cancel
            </Button>
            <Button onClick={(e) => this.deleteProject()}>Delete</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showModel}
          onClose={(e) => this.handleClose("viewProject")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="main-dialog-area"
        >
          <div className="dialog-header">
            <h3 className="dialog-title">{"Project Details"}</h3>
            <IconButton
              className="dialog-close"
              aria-label="close"
              onClick={(e) => this.handleClose("viewProject")}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-content">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Project Name</div>
                  <div className="info-value">
                    {projectDataList &&
                    projectDataList.ProjectName !== "" &&
                    projectDataList.ProjectName !== null
                      ? projectDataList.ProjectName
                      : "N/A"}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Client Name</div>
                  <div className="info-value">
                    {projectDataList &&
                    projectDataList.ClientID !== "" &&
                    projectDataList.ClientID !== null
                      ? projectDataList.ClientID
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Project Manager</div>
                  <div className="info-value">
                    {projectDataList &&
                    projectDataList.ProjectManager !== "" &&
                    projectDataList.ProjectManager !== null
                      ? projectDataList.ProjectManager
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Project Description</div>
                  <div className="info-value">
                    {projectDataList &&
                    projectDataList.Description !== "" &&
                    projectDataList.Description !== null
                      ? ReactHtmlParser(projectDataList.Description)
                      : "N/A"}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Project;
