import React from 'react';
import { Route, Redirect, useLocation } from "react-router-dom";
import Sidebar from '../components/Sidebar/Sidebar';
import MainHeader from '../components/Header/Header';

const ProtectedRoute = ({
    component: Component,
    meta,
    ...otherProps
}) => {

    React.useEffect(() => {
        document.title = meta.title;
    }, [meta])

    const isLoggedIn = localStorage.getItem('loginData');
    const location = useLocation();

    const masterLayout = (props) => (
        // <div id="main-section" className="main-section">
        //     <Sidebar {...props} />
            <div id="main-section" className="main-dashboard-section primary-bg-color">
                    <MainHeader {...props} />
                    <Component {...props} />


            </div>
        // </div>
    )

    if (isLoggedIn) {

        return (
            <Route
                {...otherProps}
                render={(props) => masterLayout(props)}
            />
        )
    }

    return (
        <Redirect
            to={{
                pathname: '/login',
                state: {
                    from: `${location.pathname}${location.search || ''}${location?.hash || ''}`
                }
            }}
        />
    )
}

export default ProtectedRoute;
