import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import CommonConfig from '../../utils/constant';
import api from '../../utils/apiClient';
import APIConstant from '../../utils/pathConstant';
import forgotPic from '../../assets/images/login-punch 2.png'
import eqLogo from '../../assets/images/EquatorLogo.png.png'
import mainSvg from '../../assets/images/Forgotpassword.svg';
import fbSvg from '../../assets/icons/facebook icon.png.png';
import twitterSvg from '../../assets/icons/twitter icon.png.png';
import githubSvg from '../../assets/icons/github icon.png.png';
import googleSvg from '../../assets/icons/google icon.png.png';

const intialState = {
    email: "",
    emailErr: false,
    emailErrText: '',
}

export default class ForgotPassword extends Component {
    constructor() {
        super()
        this.state = intialState;

    }
    handleChange = (e, type) => {
        if (type === "email") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ email: '', emailErr: true, emailErrText: 'Please enter email.' });
            } else {
                this.setState({ email: e.target.value, emailErr: false, emailErrText: '' })
            }
        }
    }

    validate = () => {
        const { email } = this.state;
        let IsValid = true;
        if (CommonConfig.isEmpty(email)) {
            this.setState({ emailErr: true, emailErrText: 'Email is required' })
            IsValid = false;
        }
        else if (!this.state.email.match(CommonConfig.RegExp.email)) {
            this.setState({ emailErr: true, emailErrText: 'Email  is not valid' });
            IsValid = false;
        }
        else {
            this.setState({ emailErr: false, emailErrText: '' })
        }
        return IsValid;
    }
    handleClick = (e) => {
        e.preventDefault();
        if (this.validate()) {
            const { email } = this.state;
            let data = {
                LoginID: email
            }
            try {
                api.post(APIConstant.path.forgotPassword, data)
                    .then((res) => {
                        if (res.success) {
                            alert(res.message)
                            console.log(res);
                            localStorage.setItem('forgotPassword', JSON.stringify(res.data));
                            localStorage.setItem('isAuthorized', res.success);
                            this.props.history.push('/');
                        } else {
                            alert(res.message)
                            console.log(res);
                            this.setState({ email: '' });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            } catch (err) {
                console.log(err);
            }
        }
    }
    render() {
        const { email, emailErr, emailErrText } = this.state;
        return (

            <div className="main-auth-section">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div>
                            <h1>Forgot Password ?</h1>
                        </div>
                        {/* </div>
        <div className="row"> */}

                        <div >
                            <p>
                                Don’t worry resetting your password is easy.Just type in
                                <br />
                                the email you registered.
                            </p>
                        </div>
                        {/* </div>
        <div className="row"> */}
                        <div >
                            <form action="/action_page.php" className="form">
                                <div className="email-password">
                                    <div className="icon">
                                        <div className="input-icon">
                                            <TextField
                                                className="w-100"
                                                required
                                                type="text"
                                                variant="standard"
                                                label="Email"
                                                name="userName"
                                                value={email}
                                                onChange={(e) => this.handleChange(e, "email")}
                                                error={emailErr}
                                                helperText={emailErrText}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button type="button" value="LOG IN" id="submit" onClick={(e) => this.handleClick(e)}>
                                    Send Email
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="image-box">
                            <img src={mainSvg} />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    {/* <div class="bottom-shade">
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 406" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 215.648L1440 0V406H0V215.648Z" fill="#3A3541" fill-opacity="0.04"/>
                  </svg>
              </div> */}
                    <div className="col-md-2 col-sm-12">
                        <div className="logo-box">
                            <img src={eqLogo} />
                        </div>
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="">
                            <div className="or d-flex justify-content-center">
                                <hr />
                                <label>or</label>
                                <hr />
                            </div>
                            <div className="logo">
                                <div className="facebook-icon">
                                    <img src={fbSvg} />
                                </div>
                                <div className="twitter-icon">
                                    <img src={twitterSvg} />
                                </div>
                                <div className="github-icon">
                                    <img src={githubSvg} />
                                </div>
                                <div className="google-icon">
                                    <img src={googleSvg} />
                                </div>
                            </div>
                            <div className="creat-account">
                                <a href="">
                                    {" "}
                                    Did you remember your password ? &nbsp; <span href="javascript:;" onClick={() =>
                                        this.props.history.push("/login")
                                    }>Log in</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        )
    }
}
