import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataGrid } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import CommonConfig from "../../utils/constant";
import api from "../../utils/apiClient";
import APIConstant from "../../utils/pathConstant";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Grid from "@mui/material/Grid";
import DoneIcon from "@mui/icons-material/Done";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Radio from "@mui/joy/Radio";
import {
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
} from "@mui/material/";
import Loader from "../Shared/Loader/Loader";
import Toast from "../Shared/Toast/Toast";
import moment from "moment";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Box } from "@mui/system";
import ReactHtmlParser from "react-html-parser";
import ReactQuill from "react-quill";

const initialState = {
  securityUserID: "",
  role: "",
  leaveID: "",
  leaveList: [],
  leaveTypeList: [],
  visibleRight: false,

  title: "",
  titleErr: false,
  titleErrText: "",

  leaveStatus: "",
  leaveStatusErr: false,
  leaveStatusErrText: "",

  leaveStartDate: null,
  leaveStartDateErr: false,
  leaveStartDateErrText: "",

  leaveEndDate: null,
  leaveEndDateErr: false,
  leaveEndDateErrText: "",

  file: "",
  fileErr: false,
  fileErrText: "",

  approver: "",
  approverErr: false,
  approverErrText: "",

  leaveType: "",
  leaveTypeErr: false,
  leaveTypeErrText: "",

  description: "",
  descriptionErr: false,
  descriptionErrText: "",

  openModel: false,
  openDialogBox: false,
  showModel: false,
  leaveDataList: [],
  isEdit: false,
  rejectDialogBox: false,
  approveDialogBox: false,
  showMyLeave: false,
  isFullDay: false,
  userID: "",
  showAllLeave: false,
  formats: [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ],
  modules: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
  },
};

export class Leave extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
    await this.getLeave();
    await this.getLeaveTypeList();
  };

  resetScreen = () => {
    this.setState({
      title: "",
      titleErr: false,
      titleErrText: "",

      leaveStatus: "",
      leaveStatusErr: false,
      leaveStatusErrText: "",

      leaveStartDate: null,
      leaveStartDateErr: false,
      leaveStartDateErrText: "",

      leaveEndDate: null,
      leaveEndDateErr: false,
      leaveEndDateErrText: "",

      file: "",
      fileErr: false,
      fileErrText: "",

      approver: "",
      approverErr: false,
      approverErrText: "",

      leaveType: "",
      leaveTypeErr: false,
      leaveTypeErrText: "",

      description: "",
      descriptionErr: false,
      descriptionErrText: "",
    });
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
    this.getLeaveTypeList();
  };

  validate = () => {
    const { title, leaveStartDate, leaveType } = this.state;
    let isValid = true;

    if (CommonConfig.isEmpty(leaveStartDate)) {
      this.setState({
        leaveStartDateErr: true,
        leaveStartDateErrText: "Please select start date",
      });
      isValid = false;
    } else {
      this.setState({ leaveStartDateErr: false, leaveStartDateErrText: "" });
    }
    if (CommonConfig.isEmpty(title)) {
      isValid = false;
      this.setState({
        titleErr: true,
        titleErrText: "Please select title",
      });
    } else {
      this.setState({ titleErr: false, titleErrText: "" });
    }
    if (CommonConfig.isEmpty(leaveType)) {
      isValid = false;
      this.setState({
        leaveTypeErr: true,
        leaveTypeErrText: "Please select leave type",
      });
    } else {
      this.setState({ leaveTypeErr: false, leaveTypeErrText: "" });
    }
    return isValid;
  };

  handleChange = (e, type) => {
    if (type === "title") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          title: "",
          titleErr: true,
          titleErrText: "Please enter title",
        });
      } else {
        this.setState({
          title: e.target.value,
          titleErr: false,
          titleErrText: "",
        });
      }
    }
    if (type === "approver") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          approver: "",
          approverErr: true,
          approverErrText: "Please select approver",
        });
      } else {
        this.setState({
          approver: e.target.value,
          approverErr: false,
          approverErrText: "",
        });
      }
    }
    if (type === "leaveType") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          leaveType: "",
          leaveTypeErr: true,
          leaveTypeErrText: "Please select leave type",
        });
      } else {
        this.setState({
          leaveType: e.target.value,
          leaveTypeErr: false,
          leaveTypeErrText: "",
        });
      }
    }
    if (type == "leaveStartDate") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          leaveStartDate: "",
          leaveStartDateErr: true,
          leaveStartDateErrText: "Please select leave start date",
        });
      } else {
        this.setState({
          leaveStartDate: e,
          leaveStartDateErr: false,
          leaveStartDateErrText: "",
        });
      }
    }
    if (type == "leaveEndDate") {
      if (e) {
        this.setState({
          leaveEndDate: e,
          leaveEndDateErr: false,
          leaveEndDateErrText: "",
        });
      }
    }
    if (type === "description") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          description: "",
          descriptionErr: true,
          descriptionErrText: "Please enter description",
        });
      } else {
        this.setState({
          description: e,
          descriptionErr: false,
          descriptionErrText: "",
        });
      }
    }
  };

  getLeave = async () => {
    Loader.show();
    try {
      let data = {
        UserID: CommonConfig.loginData().SecurityUserID,
      };
      await api
        .post(APIConstant.path.getLeave, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ leaveList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getLeaveTypeList = async () => {
    Loader.show();
    try {
      let data = {
        StringMapType: "LEAVETYPE",
      };
      await api
        .post(APIConstant.path.getStringMap, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ leaveTypeList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  addUpdateLeave = (e) => {
    e.preventDefault();
    if (this.validate()) {
      Loader.show();
      const {
        title,
        leaveType,
        leaveStartDate,
        leaveEndDate,
        description,
        leaveID,
        isFullDay,
      } = this.state;
      try {
        let data = {
          SecurityUserID: CommonConfig.loginData().SecurityUserID,
          LeaveID: leaveID,
          Title: title,
          LeaveMode: isFullDay ? "1" : "0",
          LeaveType: leaveType,
          LeaveStatus: "Pending",
          LeaveStartDate: leaveStartDate
            ? moment(leaveStartDate).format("YYYY-MM-DD")
            : "",
          LeaveEndDate: leaveEndDate
            ? moment(leaveEndDate).format("YYYY-MM-DD")
            : "",
          Description: description,
        };
        api
          .post(APIConstant.path.addUpdateLeave, data)
          .then((res) => {
            if (res.success) {
              this.resetScreen();
              this.handleClose("sidebar");
              this.getLeave();
              Loader.hide();
              Toast.success({ message: res.message });
            } else {
              this.resetScreen();
              this.handleClose("sidebar");
              Loader.hide();
              Toast.error({ message: res.message });
            }
          })
          .catch((err) => {
            Loader.hide();
            Toast.error({ message: err });
          });
      } catch (err) {
        Loader.hide();
        Toast.error({ message: err });
      }
    }
  };

  getLeaveByID = (leaveID) => {
    Loader.show();
    try {
      let data = {
        LeaveID: leaveID,
      };
      api
        .post(APIConstant.path.getLeaveByID, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({
              leaveDataList: response.data[0],
              title: response.data[0].Title,
              isFullDay: response.data[0].LeaveMode == 1 ? true : false,
              leaveType: response.data[0].LeaveType,
              leaveStartDate: response.data[0].LeaveStartDate,
              leaveEndDate: response.data[0].LeaveEndDate,
              description: response.data[0].Description,
            });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  deleteLeave = () => {
    Loader.show();
    try {
      let UserID =
        this.state.role === "Project Manager" || this.state.role === "CEO"
          ? this.state.userID
          : this.state.securityUserID;
      let data = {
        LeaveID: this.state.leaveID,
        SecurityUserID: UserID,
      };
      api
        .post(APIConstant.path.deleteLeave, data)
        .then((res) => {
          if (res.success) {
            this.handleClose("deleteLeave");
            this.getLeave();
            Loader.hide();
            Toast.success({ message: res.message });
          } else {
            this.handleClose("deleteLeave");
            Loader.hide();
            Toast.error({ message: res.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getManageLeave = async () => {
    Loader.show();
    try {
      let data = {
        UserID: CommonConfig.loginData().SecurityUserID,
      };
      await api
        .post(APIConstant.path.getManageLeave, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ leaveList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  updateLeaveStatus = (e, leaveStatus) => {
    Loader.show();
    try {
      let data = {
        LeaveID: this.state.leaveID,
        SecurityUserID: this.state.securityUserID,
        LeaveStatus: leaveStatus,
      };
      api
        .post(APIConstant.path.updateLeaveStatus, data)
        .then((res) => {
          if (res.success) {
            this.handleClose(leaveStatus);
            this.getManageLeave();
            Loader.hide();
            Toast.success({ message: res.message });
          } else {
            Loader.hide();
            Toast.error({ message: res.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  handleOpen = (item, type) => {
    if (type === "sidebar") {
      this.setState({ openModel: true });
    } else if (type === "viewLeave") {
      this.setState({ showModel: true });
      this.getLeaveByID(item.LeaveMasterID);
    } else if (type === "deleteLeave") {
      this.setState({
        openDialogBox: true,
        leaveID: item.LeaveMasterID,
        userID: item.SecurityUserID,
      });
    } else if (type === "editLeave") {
      this.setState({
        isEdit: true,
        openModel: true,
        leaveID: item.LeaveMasterID,
      });
      this.getLeaveByID(item.LeaveMasterID);
    } else if (type === "Reject") {
      this.setState({ rejectDialogBox: true, leaveID: item.LeaveMasterID });
    } else if (type === "Approve") {
      this.setState({ approveDialogBox: true, leaveID: item.LeaveMasterID });
    } else if (type === "showManageLeave") {
      this.setState({ showMyLeave: true });
      this.getManageLeave();
    } else if (type === "showMyLeave") {
      this.setState({ showMyLeave: false });
      this.getLeave();
    }
  };

  handleClose = (type) => {
    if (type === "sidebar") {
      this.resetScreen();
      this.setState({ openModel: false });
      this.getLeave();
    } else if (type === "viewLeave") {
      this.setState({ showModel: false, projectMasterID: "" });
      this.resetScreen();
    } else if (type === "deleteLeave") {
      this.setState({
        openDialogBox: false,
        leaveID: "",
      });
      this.resetScreen();
    } else if (type === "DialogBox") {
      this.setState({
        openDialogBox: false,
      });
      this.getLeave();
    } else if (type === "Reject") {
      this.setState({ rejectDialogBox: false, leaveID: "" });
    } else if (type === "Approve") {
      this.setState({ approveDialogBox: false, leaveID: "" });
    }
  };

  actionBodyTemplate = () => {
    return [
      { field: "FullName", headerName: "Employee Name", width: 150 },
      { field: "TotalLeave", headerName: "Total Leave", width: 100 },
      { field: "Title", headerName: "Leave Title", width: 100 },
      { field: "LeaveStartDate", headerName: "Start Date", width: 125 },
      { field: "LeaveEndDate", headerName: "End Date", width: 100 },
      { field: "LeaveType", headerName: "Leave Type", width: 125 },
      { field: "LeaveStatus", headerName: "Status", width: 100 },
      {
        field: "Actions",
        headerName: "Actions",
        type: "actions",
        width: 200,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            onClick={(e) => this.handleOpen(params.row, "viewLeave")}
          />,
          params.row.LeaveStatus === "Pending" ? (
            <>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={(e) => this.handleOpen(params.row, "editLeave")}
              />
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={(e) => this.handleOpen(params.row, "deleteLeave")}
              />
              {this.state.showMyLeave ? (
                <>
                  <GridActionsCellItem
                    icon={<DoneIcon />}
                    label="Edit"
                    onClick={(e) => this.handleOpen(params.row, "Approve")}
                  />

                  <GridActionsCellItem
                    icon={<CloseSharpIcon />}
                    label="Reject"
                    onClick={(e) => this.handleOpen(params.row, "Reject")}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          ),
        ],
      },
    ];
  };

  disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  render() {
    const {
      openDialogBox,
      role,
      title,
      titleErrText,
      titleErr,
      leaveStartDate,
      leaveStartDateErr,
      leaveStartDateErrText,
      leaveEndDate,
      leaveEndDateErr,
      leaveEndDateErrText,
      description,
      descriptionErr,
      descriptionErrText,
      leaveType,
      leaveTypeErr,
      leaveTypeErrText,
      leaveList,
      leaveTypeList,
      leaveDataList,
      showModel,
      isEdit,
      approveDialogBox,
      rejectDialogBox,
      showMyLeave,
      isFullDay,
      showAllLeave,
      openModel,
      modules,
      formats,
    } = this.state;

    return (
      <div className="main-container">
        <div className="main-header">
          <div className="title-action-row">
            {!showMyLeave ? <h3>Leave</h3> : <h3>All Leave</h3>}
            <div className="title-action-row action-buttons">
              {role === "Project Manager" || role === "CEO" ? (
                <div className="filter-leave">
                  <ButtonGroup
                    className="button-group"
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      className={!showMyLeave ? "active" : "inactive"}
                      onClick={(e) => this.handleOpen(e, "showMyLeave")}
                    >
                      My Leave
                    </Button>

                    <Button
                      className={showMyLeave ? "active" : "inactive"}
                      onClick={(e) => this.handleOpen(e, "showManageLeave")}
                    >
                      All Leave
                    </Button>
                  </ButtonGroup>
                </div>
              ) : null}
              {!showMyLeave || !role === "CEO" ? (
                <div
                  className="primary-btn"
                  onClick={(e) => this.handleOpen(e, "sidebar")}
                >
                  Add Leave
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="main-container-body">
          <DataGrid
            sx={{
              boxShadow: 2,
              border: "none",
            }}
            rows={leaveList}
            columns={this.actionBodyTemplate()}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.LeaveMasterID}
            hideFooterSelectedRowCount
          />
        </div>

        <Drawer
          PaperProps={{
            sx: { width: "30%" },
          }}
          open={openModel}
          anchor="right"
        >
          <div className="main-drawer-area">
            <div className="drawer-header">
              <div>{isEdit ? "Edit Leave" : "Add Leave"}</div>

              <div
                className="close-btn"
                onClick={(e) => this.handleClose("sidebar")}
              >
                {"X"}
                <i className="icon-close"></i>
              </div>
            </div>
            <div className="drawer-body">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl>
                      <TextField
                        label="Leave Title:"
                        variant="outlined"
                        type="title"
                        name="title"
                        value={title}
                        onChange={(e) => this.handleChange(e, "title")}
                      />
                      {titleErr
                        ? CommonConfig.showErrorMsg(titleErrText)
                        : null}
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <label>Leave Mode</label>
                  <Box>
                    <Radio
                      checked={this.state.isFullDay}
                      onChange={(e) =>
                        this.setState({ isFullDay: !this.state.isFullDay })
                      }
                      value="FullDay"
                      name="radio-buttons"
                      componentsProps={{ input: { "aria-label": "A" } }}
                      label="Full Day"
                    />
                    <br></br>
                    <Radio
                      checked={!this.state.isFullDay}
                      onChange={(e) =>
                        this.setState({ isFullDay: !this.state.isFullDay })
                      }
                      value="HalfDay"
                      name="radio-buttons"
                      label="Half Day"
                      componentsProps={{ input: { "aria-label": "B" } }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            hintText="Weekends Disabled"
                            shouldDisableDate={this.disableWeekends}
                            label="Start Date"
                            inputFormat="dd/MM/yyyy"
                            value={leaveStartDate}
                            onChange={(e) =>
                              this.handleChange(e, "leaveStartDate")
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                        {leaveStartDateErr
                          ? CommonConfig.showErrorMsg(leaveStartDateErrText)
                          : null}
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            hintText="Weekends Disabled"
                            shouldDisableDate={this.disableWeekends}
                            label="End Date"
                            inputFormat="dd/MM/yyyy"
                            value={leaveEndDate}
                            minDate={new Date(leaveStartDate)}
                            onChange={(e) =>
                              this.handleChange(e, "leaveEndDate")
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                        {leaveEndDateErr
                          ? CommonConfig.showErrorMsg(leaveEndDateErrText)
                          : null}
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl>
                      <InputLabel id="leaveType">Select Leave Type</InputLabel>
                      <Select
                        value={leaveType}
                        onChange={(e) => this.handleChange(e, "leaveType")}
                        label="Select Leave Type"
                      >
                        {leaveTypeList.map((p) => (
                          <MenuItem key={p.label + p.value} value={p.value}>
                            {p.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {leaveTypeErr
                        ? CommonConfig.showErrorMsg(leaveTypeErrText)
                        : null}
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      value={description}
                      onChange={(e) => this.handleChange(e, "description")}
                    ></ReactQuill>
                    {descriptionErr
                      ? CommonConfig.showErrorMsg(descriptionErrText)
                      : null}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="drawer-footer">
              <div
                className="secondary-btn"
                onClick={(e) => this.handleClose("sidebar")}
              >
                Cancel
              </div>
              <div
                className="primary-btn"
                onClick={(e) => this.addUpdateLeave(e)}
              >
                Submit
              </div>
            </div>
          </div>
        </Drawer>

        <Dialog
          open={openDialogBox}
          onClose={(e) => this.handleClose("deleteLeave")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h4 className="dialog-title">{"Delete Leave"}</h4>
            <IconButton
              aria-label="close"
              onClick={(e) => this.handleClose("deleteLeave")}
              sx={{
                position: "absolute",
                right: -10,
                top: 15,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {"Are you sure you want to delete this Leave?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={(e) => this.handleClose("deleteLeave")}>
              Cancel
            </Button>
            <Button onClick={(e) => this.deleteLeave()}>Delete</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showModel}
          onClose={(e) => this.handleClose("viewLeave")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="main-dialog-area"
        >
          <div className="dialog-header">
            <h3 className="dialog-title">{"Leave Details"}</h3>
            <IconButton
              aria-label="close"
              className="dialog-close"
              onClick={(e) => this.handleClose("viewLeave")}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-content">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Leave Title</div>
                  <div className="info-value">
                    {leaveDataList &&
                    leaveDataList.Title !== "" &&
                    leaveDataList.Title !== null
                      ? leaveDataList.Title
                      : "N/A"}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Start Date</div>
                  <div className="info-value">
                    {leaveDataList &&
                    leaveDataList.LeaveStartDateName !== "" &&
                    leaveDataList.LeaveStartDateName !== null
                      ? leaveDataList.LeaveStartDateName
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">End Date</div>
                  <div className="info-value">
                    {leaveDataList &&
                    leaveDataList.LeaveEndDateName !== "" &&
                    leaveDataList.LeaveEndDateName !== null
                      ? leaveDataList.LeaveEndDateName
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Leave Type</div>
                  <div className="info-value">
                    {leaveDataList &&
                    leaveDataList.LeaveTypeName !== "" &&
                    leaveDataList.LeaveTypeName !== null
                      ? leaveDataList.LeaveTypeName
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Leave Description</div>
                  <div className="info-value">
                    {leaveDataList &&
                    leaveDataList.Description !== "" &&
                    leaveDataList.Description !== null
                      ? ReactHtmlParser(leaveDataList.Description)
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Leave Status</div>
                  <div className="info-value">
                    {leaveDataList &&
                    leaveDataList.LeaveStatus !== "" &&
                    leaveDataList.LeaveStatus !== null
                      ? leaveDataList.LeaveStatus
                      : "N/A"}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Dialog>

        <Dialog
          open={rejectDialogBox}
          onClose={(e) => this.handleClose("Reject")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <IconButton
              aria-label="close"
              onClick={(e) => this.handleClose("Reject")}
              sx={{
                position: "absolute",
                right: -10,
                top: 15,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {"Are you sure you want to reject this Leave?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={(e) => this.handleClose("Reject")}>Cancel</Button>
            <Button onClick={(e) => this.updateLeaveStatus(e, "Reject")}>
              Reject
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={approveDialogBox}
          onClose={(e) => this.handleClose("Approve")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <IconButton
              aria-label="close"
              onClick={(e) => this.handleClose("Approve")}
              sx={{
                position: "absolute",
                right: -10,
                top: 15,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {"Are you sure you want to Approve this Leave?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={(e) => this.handleClose("Approve")}>Cancel</Button>
            <Button onClick={(e) => this.updateLeaveStatus(e, "Approve")}>
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Leave;
