import React, { Component } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import CommonConfig from "../../utils/constant";
import api from "../../utils/apiClient";
import APIConstant from "../../utils/pathConstant";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "../Shared/Loader/Loader";
import Toast from "../Shared/Toast/Toast";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const initialState = {
  securityUserID: "",
  role: "",
  getUserList: [],
  userRoleList: [],
  userList: [],
  userID: "",
  showModel: false,
  openModel: false,
  openDialogBox: false,
  userAccessModel: false,

  firstName: "",
  firstNameErr: false,
  firstNameErrText: "",

  lastName: "",
  lastNameErr: false,
  lastNameErrText: "",

  email: "",
  emailErr: false,
  emailErrText: "",

  phone: "",
  phoneErr: false,
  phoneErrText: "",

  userRole: "",
  userRoleErr: false,
  userRoleErrText: "",
};

export class User extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = async () => {
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
    await this.getUserList();
    await this.getUserRole();
  };

  resetScreen = () => {
    this.setState(initialState);
    this.getUserList();
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
    this.getUserRole();
  };

  getUserList = async () => {
    Loader.show();
    try {
      let data = {
        UserID: CommonConfig.loginData().SecurityUserID,
      };
      await api
        .post(APIConstant.path.getUserList, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ getUserList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getUserListByID = async (userID) => {
    Loader.show();
    try {
      let data = {
        UserID: userID,
      };
      await api
        .post(APIConstant.path.getUserListByID, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ userList: response.data[0] });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getUserByID = async (userID) => {
    Loader.show();
    try {
      let data = {
        UserID: userID,
      };
      await api
        .post(APIConstant.path.getUserByID, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({
              firstName: response.data[0].FirstName,
              lastName: response.data[0].LastName,
              email: response.data[0].Email,
              userRole: response.data[0].UserRole,
            });

            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  deleteUser = () => {
    Loader.show();
    try {
      let data = {
        UserID: this.state.userID,
        SecurityUserID: CommonConfig.loginData().SecurityUserID,
      };
      api
        .post(APIConstant.path.deleteUser, data)
        .then((res) => {
          if (res.success) {
            Toast.success({ message: res.message });
            Loader.hide();
            this.handleClose("deleteUser");
            this.getUserList();
          } else {
            Toast.error({ message: res.message });
            this.handleClose("deleteUser");
            Loader.hide();
          }
        })
        .catch((err) => {
          Toast.error({ message: err });
          Loader.hide();
        });
    } catch (err) {
      Toast.error({ message: err });
      Loader.hide();
    }
  };

  handleOpen = (item, type) => {
    if (type === "viewUser") {
      this.setState({ showModel: true });
      this.getUserListByID(item.UserID);
    } else if (type === "deleteUser") {
      this.setState({ openModel: true, userID: item.UserID });
    } else if (type === "sidebar") {
      this.setState({ openDialogBox: true });
      this.getUserByID(item.UserID);
      this.setState({ userID: item.UserID });
    }
  };

  handleClose = (type) => {
    if (type === "viewUser") {
      this.setState({ showModel: false });
    } else if (type === "deleteUser") {
      this.setState({ openModel: false, userID: "" });
    } else if (type === "sidebar") {
      this.setState({ openDialogBox: false });
      this.setState({ userID: "" });
    }
  };

  handleChange = (e, type) => {
    if (type === "firstName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          firstName: "",
          firstNameErr: true,
          firstNameErrText: "Please enter first name",
        });
      } else {
        this.setState({
          firstName: e.target.value,
          firstNameErr: false,
          firstNameErrText: "",
        });
      }
    }
    if (type === "lastName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          lastName: "",
          lastNameErr: true,
          lastNameErrText: "Please enter last name",
        });
      } else {
        this.setState({
          lastName: e.target.value,
          lastNameErr: false,
          lastNameErrText: "",
        });
      }
    }
    if (type === "email") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          email: "",
          emailErr: true,
          emailErrText: "Please enter email",
        });
      } else {
        this.setState({
          email: e.target.value,
          emailErr: false,
          emailErrText: "",
        });
      }
    }
    if (type === "userRole") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          userRole: "",
          userRoleErr: true,
          userRoleErrText: "Please select user role",
        });
      } else {
        this.setState({
          userRole: e.target.value,
          userRoleErr: false,
          userRoleErrText: "",
        });
      }
    }
  };

  validate = () => {
    const { firstName, lastName, email, userRole } = this.state;
    let isValid = true;

    if (CommonConfig.isEmpty(firstName)) {
      this.setState({
        firstNameErr: true,
        firstNameErrText: "Please enter first name",
      });
      isValid = false;
    } else {
      this.setState({ firstNameErr: false, firstNameErrText: "" });
    }

    if (CommonConfig.isEmpty(lastName)) {
      this.setState({
        lastNameErr: true,
        lastNameErrText: "Please enter last name",
      });
      isValid = false;
    } else {
      this.setState({ lastNameErr: false, lastNameErrText: "" });
    }

    if (CommonConfig.isEmpty(email)) {
      this.setState({
        emailErr: true,
        emailErrText: "Please enter email",
      });
      isValid = false;
    } else {
      this.setState({ emailErr: false, emailErrText: "" });
    }

    if (CommonConfig.isEmpty(userRole)) {
      this.setState({
        userRoleErr: true,
        userRoleErrText: "Please select user role",
      });
      isValid = false;
    } else {
      this.setState({ userRoleErr: false, userRoleErrText: "" });
    }
    return isValid;
  };

  actionBodyTemplate = () => {
    return [
      { field: "FullName", headerName: "Employee Name", width: 200 },
      { field: "Email", headerName: "Email", width: 300 },
      { field: "PhoneNumber", headerName: "Phone", width: 150 },
      { field: "UserRole", headerName: "Role", width: 200 },
      {
        field: "Actions",
        headerName: "Actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            onClick={(e) => this.handleOpen(params.row, "viewUser")}
          />,
          this.state.role === "CEO" ? (
            <>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={(e) => this.handleOpen(params.row, "sidebar")}
              />
            </>
          ) : (
            <></>
          ),

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={(e) => this.handleOpen(params.row, "deleteUser")}
          />,

          <GridActionsCellItem
            icon={<SyncLockIcon />}
            label="lock"
            onClick={(e) =>
              this.props.history.push({
                pathname: "/user-access",
                state: {
                  UserID: params.row.UserID,
                },
              })
            }
          />,
        ],
      },
    ];
  };

  getUserRole = async () => {
    try {
      await api
        .post(APIConstant.path.getUserRole)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ userRoleList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {});
    } catch (err) {
      Loader.hide();
    }
  };

  updateUser = (e) => {
    e.preventDefault();
    if (this.validate()) {
      Loader.show();
      const { firstName, lastName, email, userRole, userID } = this.state;
      try {
        let data = {
          SecurityUserID: userID,
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          UserRole: userRole,
        };
        api
          .post(APIConstant.path.updateUser, data)
          .then((res) => {
            if (res.success) {
              this.resetScreen();
              this.handleClose("sidebar");
              this.getUserList();
              Loader.hide();
              Toast.success({ message: res.message });
            } else {
              this.resetScreen();
              this.handleClose("sidebar");
              Loader.hide();
              Toast.error({ message: res.message });
            }
          })
          .catch((err) => {
            Loader.hide();
            Toast.error({ message: err });
          });
      } catch (err) {
        Loader.hide();
        Toast.error({ message: err });
      }
    }
  };
  render() {
    const {
      openDialogBox,
      getUserList,
      showModel,
      userList,
      openModel,
      firstName,
      firstNameErr,
      firstNameErrText,
      lastName,
      lastNameErr,
      lastNameErrText,
      email,
      emailErr,
      emailErrText,
      userRole,
      userRoleErr,
      userRoleErrText,
      phone,
      phoneErr,
      phoneErrText,
      userRoleList,
      userAccessModel,
    } = this.state;

    return (
      <div className="main-container">
        <div className="main-header">
          <div className="title-action-row">
            <h3>Users</h3>
          </div>
        </div>
        <div className="data-table">
          <DataGrid
            sx={{
              boxShadow: 2,
              border: "none",
            }}
            rows={getUserList}
            columns={this.actionBodyTemplate()}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.UserID}
            hideFooterSelectedRowCount
          />
        </div>

        <Drawer
          PaperProps={{
            sx: { width: "30%" },
          }}
          open={openDialogBox}
          anchor="right"
        >
          <div className="main-drawer-area">
            <div className="drawer-header">
              <div>Edit User</div>

              <div
                className="close-btn"
                onClick={(e) => this.handleClose("sidebar")}
              >
                {"X"}
                <i className="icon-close"></i>
              </div>
            </div>
            <div className="drawer-body">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl>
                      <TextField
                        label="First Name:"
                        variant="outlined"
                        type="firstName"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => this.handleChange(e, "firstName")}
                      />
                      {firstNameErr
                        ? CommonConfig.showErrorMsg(firstNameErrText)
                        : null}
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl>
                      <TextField
                        label="Last Name:"
                        variant="outlined"
                        type="lastName"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => this.handleChange(e, "lastName")}
                      />
                      {lastNameErr
                        ? CommonConfig.showErrorMsg(lastNameErrText)
                        : null}
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl>
                      <TextField
                        label="Email:"
                        variant="outlined"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => this.handleChange(e, "email")}
                      />
                      {emailErr
                        ? CommonConfig.showErrorMsg(emailErrText)
                        : null}
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <FormControl>
                      <InputLabel id="userRole">Select User Type</InputLabel>
                      <Select
                        value={userRole}
                        onChange={(e) => this.handleChange(e, "userRole")}
                        label="Select User Type"
                      >
                        {userRoleList.map((p) => (
                          <MenuItem key={p.label + p.value} value={p.value}>
                            {p.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {userRoleErr
                        ? CommonConfig.showErrorMsg(userRoleErrText)
                        : null}
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="drawer-footer">
              <div
                className="secondary-btn"
                onClick={(e) => this.handleClose("sidebar")}
              >
                Cancel
              </div>
              <div className="primary-btn" onClick={(e) => this.updateUser(e)}>
                Submit
              </div>
            </div>
          </div>
        </Drawer>

        <Dialog
          open={showModel}
          onClose={(e) => this.handleClose("viewUser")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="main-dialog-area"
        >
          <div className="dialog-header">
            <h3 className="dialog-title">{"User Details"}</h3>
            <IconButton
              className="dialog-close"
              aria-label="close"
              onClick={(e) => this.handleClose("viewUser")}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-content">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Full Name</div>
                  <div className="info-value">
                    {userList &&
                    userList.FullName !== "" &&
                    userList.FullName !== null
                      ? userList.FullName
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="dialog-item">
                  <div className="info-label">Email</div>
                  <div className="info-value">
                    {userList &&
                    userList.Email !== "" &&
                    userList.Email !== null
                      ? userList.Email
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Phone Number</div>
                  <div className="info-value">
                    {userList &&
                    userList.PhoneNumber !== "" &&
                    userList.PhoneNumber !== null
                      ? userList.PhoneNumber
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">User Role</div>
                  <div className="info-value">
                    {userList &&
                    userList.UserRole !== "" &&
                    userList.UserRole !== null
                      ? userList.UserRole
                      : "N/A"}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Dialog>

        <Dialog
          open={openModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h4 className="dialog-title">{"Delete User"}</h4>
            <IconButton
              aria-label="close"
              onClick={(e) => this.handleClose("deleteUser")}
              sx={{
                position: "absolute",
                right: -10,
                top: 15,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {"Are you sure you want to delete this User?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={(e) => this.handleClose("deleteUser")}>
              Cancel
            </Button>
            <Button onClick={(e) => this.deleteUser()}>Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default User;
