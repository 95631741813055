import Login from "./components/Login/Login";
import SignUp from "./components/Signup/SignUp";
import Task from "./components/Task/Task";
import Dashboard from "./components/Dashboard/Dashboard";
import ForgotPassword from "./components/Forgotpassword/ForgotPassword";
import Error404 from "./components/Shared/Error404/Error404";
import Project from "./components/Project/Project";
import EditProfile from "./components/EditProfile/EditProfile";
import Leave from "./components/Leave/Leave";
import User from "./components/User/User";
import UserAccess from "./components/UserAccess/UserAccess";

var dashRoutes = [
  /** Add all authentication routing (not required session) here */
  {
    path: "/",
    name: "Login",
    component: Login,
    icon: "",
    invisible: false,
    meta: {
      title: "Login",
    },
    isPreventedRoute: true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    icon: "",
    invisible: false,
    meta: {
      title: "Login",
    },
    isPreventedRoute: true,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    icon: "",
    invisible: false,
    meta: {
      title: "ForgotPassword",
    },
    isPreventedRoute: true,
  },

  {
    path: "/404Error",
    name: "Error404",
    component: Error404,
    icon: "",
    invisible: false,
    meta: {
      title: "Page not found",
    },
    isShared: true,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    icon: "",
    invisible: false,
    meta: {
      title: "Signup",
    },
    isPreventedRoute: true,
  },

  /** Add all protected routing (requires session) here */
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    icon: "",
    invisible: false,
    meta: {
      title: "Dashboard",
    },
    isPreventedRoute: false,
  },

  {
    path: "/task",
    name: "Task",
    component: Task,
    icon: "",
    invisible: false,
    meta: {
      title: "Task",
    },
    isPreventedRoute: false,
  },
  {
    path: "/project",
    name: "Project",
    component: Project,
    icon: "",
    invisible: false,
    meta: {
      title: "Project",
    },
    isPreventedRoute: false,
  },
  {
    path: "/edit-profile",
    name: "EditProfile",
    component: EditProfile,
    icon: "",
    invisible: false,
    meta: {
      title: "EditProfile",
    },
    isPreventedRoute: false,
  },
  {
    path: "/leave",
    name: "Leave",
    component: Leave,
    icon: "",
    invisible: false,
    meta: {
      title: "Leave",
    },
    isPreventedRoute: false,
  },
  {
    path: "/user",
    name: "User",
    component: User,
    icon: "",
    invisible: false,
    meta: {
      title: "Users",
    },
    isPreventedRoute: false,
  },
  {
    path: "/user-access",
    name: "UserAccess",
    component: UserAccess,
    icon: "",
    invisible: false,
    meta: {
      title: "UserAccess",
    },
    isPreventedRoute: false,
  },
];
export default dashRoutes;
