import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Paper from '@mui/material/Paper';
import api from '../../utils/apiClient';
import APIConstant from '../../utils/pathConstant';



export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {}

  }

  render() {
    return (
      <div>
        Dashboard
      </div>
    )
  }
}

