import React, { Component } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as dayjs from 'dayjs'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  MenuItem,
  Button,
  FormControl,
  Select,
  InputLabel,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
} from "@mui/material/";
import Loader from "../Shared/Loader/Loader";
import Toast from "../Shared/Toast/Toast";
import CommonConfig from "../../utils/constant";
import api from "../../utils/apiClient";
import APIConstant from "../../utils/pathConstant";
import moment from "moment";
import { Chip, TextareaAutosize } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactQuill from "react-quill";
import ReactHtmlParser from "react-html-parser";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const initialState = {
  FilterModel: false,
  TaskMasterID: "",
  taskID: "",
  role: "",
  taskList: [],
  taskSubject: "",
  taskSubjectErr: false,
  taskSubjectErrText: "",

  project: "",
  projectErr: false,
  projectErrText: "",

  status: "",
  statusErr: false,
  statusErrText: "",

  dueDate: "",
  dueDateErr: false,
  dueDateErrText: "",

  assignedDate: "",
  assignedDateErr: false,
  assignedDateErrText: "",

  observer: [],
  observerErr: false,
  observerErrText: "",

  issueType: "",
  issueTypeErr: false,
  issueTypeErrText: "",

  assignee: "",
  assigneeErr: "",
  assigneeErrText: "",

  description: "",
  descriptionErr: false,
  descriptionErrText: "",

  timeSheetType: "",
  timeSheetTypeErr: "false",
  timeSheetTypeErrText: "",

  timeSheetAssignee: "",
  timeSheetAssigneeErr: "",
  timeSheetAssigneeErrText: "",

  observerList: [],
  projectList: [],
  assigneeList: [],
  issueTypeList: [],
  openModel: false,
  statusList: [],
  openDialogBox: false,
  showModel: false,
  taskDataList: [],
  observerData: [],
  classes: "",
  isEdit: false,
  assigneeName: [],
  filteredTaskList: [],
  startDate: "",
  filteredDates: [],
  filterdStatus: [],
  filterStatus: "",
  timeSheet: "",
  timeSheetErr: false,
  timeSheetErrText: "",
  timeSheetModel: false,
  timeSheetList: [],
  timeSheetTypeList: [],
  showProjectList: false,
  fileName: "",
  timeSheetDate: "",
  projectDataList: [],
  timeSheetFileName: '',
  ClientTimeSheetName: '',
  sheetTypeName: "",
  monthTask: Date(),

};

class Task extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this._exporter = React.createRef();
  }

  componentDidMount = async () => {
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
      assignee: userData.SecurityUserID
    });
    await this.getTask();
    await this.getStringMap();
    await this.getProjectDropDown();
    await this.getStatusDropDown();
    await this.getAssigneeList();
    await this.getObserverList();
    await this.getTimeSheetType();
  };

  resetScreen = () => {
    this.setState(initialState);
    const userData = CommonConfig.loginData();
    this.setState({
      securityUserID: userData.SecurityUserID,
      role: userData.Role,
    });
    this.getTask();
    this.getStringMap();
    this.getProjectDropDown();
    this.getStatusDropDown();
    this.getAssigneeList();
    this.getObserverList();
    this.getTimeSheetType();
  };

  validate = () => {
    const {
      assignee,
      issueType,
      observer,
      project,
      assignedDate,
      dueDate,
      taskSubject,
      status,
    } = this.state;
    let IsValid = true;

    if (CommonConfig.isEmpty(assignee)) {
      this.setState({
        assigneeErr: true,
        assigneeErrText: "Please select assigne",
      });
      IsValid = false;
    } else {
      this.setState({ assigneeErr: false, assigneeErrText: "" });
    }
    if (CommonConfig.isEmpty(issueType)) {
      this.setState({
        issueTypeErr: true,
        issueTypeErrText: "Please select issue",
      });
      IsValid = false;
    } else {
      this.setState({ issueTypeErr: false, issueTypeErrText: "" });
    }
    if (CommonConfig.isEmpty(observer)) {
      this.setState({
        observerErr: true,
        observerErrText: "Please select observer",
      });
      IsValid = false;
    } else {
      this.setState({ observerErr: false, observerErrText: "" });
    }
    if (CommonConfig.isEmpty(project)) {
      this.setState({
        projectErr: true,
        projectErrText: "Please select project",
      });
      IsValid = false;
    } else {
      this.setState({ projectErr: false, projectErrText: "" });
    }
    if (CommonConfig.isEmpty(assignedDate)) {
      this.setState({
        assignedDateErr: true,
        assignedDateErrText: "Please select assigned date",
      });
      IsValid = false;
    } else {
      this.setState({ assignedDateErr: false, assignedDateErrText: "" });
    }
    if (CommonConfig.isEmpty(dueDate)) {
      this.setState({
        dueDateErr: true,
        dueDateErrText: "Please select due date",
      });
      IsValid = false;
    } else {
      this.setState({ dueDateErr: false, dueDateErrText: "" });
    }
    if (CommonConfig.isEmpty(taskSubject)) {
      this.setState({
        taskSubjectErr: true,
        taskSubjectErrText: " Please select task subject",
      });
      IsValid = false;
    } else {
      this.setState({ taskSubjectErr: false, taskSubjectErrText: "" });
    }
    if (CommonConfig.isEmpty(status)) {
      this.setState({
        statusErr: true,
        statusErrText: " Please select status",
      });
      IsValid = false;
    } else {
      this.setState({ statusErr: false, statusErrText: "" });
    }

    return IsValid;
  };

  handleChange = (e, type) => {
    if (type === "taskSubject") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          taskSubject: "",
          taskSubjectErr: true,
          taskSubjectErrText: "Please enter task subject.",
        });
      } else {
        this.setState({
          taskSubject: e.target.value,
          taskSubjectErr: false,
          taskSubjectErrText: "",
        });
      }
    } else if (type === "observer") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          observer: "",
          observerErr: false,
          observerErrText: "Please select observer.",
        });
      } else {
        this.setState({
          observer:
            typeof e.target.value === "string"
              ? e.target.value.split(",")
              : e.target.value,
          observerErr: false,
          observerErrText: "",
        });
      }
    } else if (type === "dueDate") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          dueDate: "",
          dueDateErr: true,
          dueDateErrText: "Please enter due date",
        });
      } else {
        this.setState({ dueDate: e, dueDateErr: false, dueDateErrText: "" });
      }
    } else if (type === "assignedDate") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          assignedDate: "",
          assignedDateErr: true,
          assignedDateErrText: "Please select assigned date",
        });
      } else {
        this.setState({
          assignedDate: e,
          dueDate: e,
          assignedDateErr: false,
          assignedDateErrText: "",
        });
      }
    } else if (type === "project") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          project: "",
          projectErr: true,
          projectErrText: "Please select project",
        });
      } else {
        this.setState({
          project: e.target.value,
          projectErr: false,
          projectErrText: "",
        });
      }
    } else if (type === "issueType") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          issueType: "",
          issueTypeErr: true,
          issueTypeErrText: "Please select issue",
        });
      } else {
        this.setState({
          issueType: e.target.value,
          issueTypeErr: false,
          issueTypeErrText: "",
        });
      }
    } else if (type === "assignee") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          assignee: "",
          assigneeErr: true,
          assigneeErrText: "Please select Assignee",
        });
      } else {
        this.setState({
          assignee: e.target.value,
          assigneeErr: false,
          assigneeErrText: "",
        });
      }
    } else if (type === "description") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          description: "",
          descriptionErr: true,
          descriptionErrText: "Please enter description",
        });
      } else {
        this.setState({
          description: e,
          descriptionErr: false,
          descriptionErrText: "",
        });
      }
    } else if (type === "status") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          status: "",
          statusErr: true,
          statusErrText: "Please select status",
        });
      } else {
        this.setState({
          status: e.target.value,
          statusErr: false,
          statusErrText: "",
        });
      }
    }
    else if (type === "todo") {
      const filterdStatus = this.state.taskList
        .filter((d) => d.Status === e.target.value)
        .map((x) => {
          return x;
        });
      this.setState({
        filterStatus: e.target.value,
        filteredTaskList: filterdStatus,
      });
    } else if (type === "inprogress") {
      const filterdStatus = this.state.taskList
        .filter((d) => d.Status === e.target.value)
        .map((x) => {
          return x;
        });
      this.setState({
        filterStatus: e.target.value,
        filteredTaskList: filterdStatus,
      });
    } else if (type === "review") {
      const filterdStatus = this.state.taskList
        .filter((d) => d.Status === e.target.value)
        .map((x) => {
          return x;
        });
      this.setState({
        filterStatus: e.target.value,
        filteredTaskList: filterdStatus,
      });
    } else if (type === "done") {
      const filterdStatus = this.state.taskList
        .filter((d) => d.Status === e.target.value)
        .map((x) => {
          return x;
        });
      this.setState({
        filterStatus: e.target.value,
        filteredTaskList: filterdStatus,
      });
    } else if (type === "all") {
      const filterdStatus = this.state.taskList;
      this.setState({
        filterStatus: e.target.value,
        filteredTaskList: filterdStatus,
      });
    }
    else if (type === "startDate") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({ startDate: "" });
        this.setState({ filteredTaskList: "" });
      } else {
        let filterDate = this.state.taskList
          .filter((d) => d.AssignedDate === moment(e).format("DD-MM-YYYY"))
          .map((x) => {
            return x;
          });
        console.log("first", filterDate, e)
        this.setState({
          startDate: e,
          filteredTaskList: filterDate,
        });
      }
    }
    else if (type === "assigneeName") {
      let data = e.target.value;
      if (CommonConfig.isEmpty(data)) {
        this.setState({ filteredTaskList: "" });
      } else {
        var filteredTaskListArr = [];
        for (let i = 0; i < data.length; i++) {
          var filteredTaskList = this.state.taskList.filter(
            (x) => x.AssigneeID === data[i].value
          );
          filteredTaskListArr.push(filteredTaskList);
        }
        let filteredTaskData = [].concat.apply([], filteredTaskListArr);
        this.setState({
          assigneeName: typeof data === "string" ? data.split(",") : data,
          filteredTaskList: filteredTaskData,
        });
      }
    }
    else if (type === "timeSheetType") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          timeSheetType: "",
          timeSheetTypeErr: true,
          timeSheetTypeErrText: "Please select time sheet type.",
        });
      } else {
        var sheetType = this.state.timeSheetTypeList.filter(
          (p) => p.value == e.target.value
        );
        this.setState({
          timeSheetType: e.target.value,
          timeSheetTypeErr: false,
          timeSheetTypeErrText: "",
        });
        this.setState({ sheetTypeName: sheetType[0].label })
        if (sheetType[0].label === "Client") {
          this.setState({ showProjectList: true });
        } else {
          this.setState({ showProjectList: false });
        }
      }
    }
    else if (type === "timeSheetProject") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          timeSheet: "",
          timeSheetErr: true,
          timeSheetErrText: "Please select project.",
        });
      } else {
        this.setState({
          timeSheet: e.target.value,
          timeSheetErr: false,
          timeSheetErrText: "",
        });
        var projectList = this.state.projectDataList.filter(
          (p) => p.value == e.target.value
        );
        var timeSheetName = projectList[0].label;
        this.setState({ ClientTimeSheetName: timeSheetName });

      }
    }
    else if (type === "projectName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          projectName: "",
        });
      } else {
        let data = e.target.value;
        if (data === "ALL") {
          this.setState({
            projectName: data,
            filteredTaskList: this.state.taskList,
          });
        } else {
          var filteredTaskList = this.state.taskList.filter(
            (x) => x.ProjectID === data
          );
          this.setState({
            projectName: data,
            filteredTaskList: filteredTaskList,
          });
        }
      }
    } else if (type === "timeSheetDate") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          timeSheetDate: "",
          timeSheetDateErr: true,
          timeSheetDateErrText: "Please select month",
        });
      } else {
        this.setState({
          timeSheetDate: e,
          timeSheetDateErr: false,
          timeSheetDateErrText: "",
        });
        console.log('timer ', e)
        if (this.state.sheetTypeName === "Client") {
          this.getClientTimeSheet(e);
        }

        if (this.state.sheetTypeName === "Employee") {
          this.getTimeSheet(this.state.timeSheetAssignee, e)
        }

        if (this.state.sheetTypeName != "Employee" && this.state.sheetTypeName != "Client") {
          this.getTimeSheet('', e)
        }
      }

    }
    else if (type === "timeSheetAssignee") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          timeSheetAssignee: "",
          timeSheetAssigneeErr: true,
          timeSheetAssigneeErrText: "Please select assignee",
        });
      } else {
        this.setState({
          timeSheetAssignee: e.target.value,
          timeSheetAssigneeErr: false,
          timeSheetAssigneeErrText: "",
        });
      }
    }
    else if (type == "monthTask") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          monthTask: "",
        });
      } else {
        var selectedMonth = (e.getMonth() + 1);
        var filteredMonthTask = this.state.taskList.filter((x) =>
          x.Month == selectedMonth
        )
        this.setState({
          monthTask: e,
          filteredTaskList: filteredMonthTask
        });

        if (this.state.projectName === "ALL") {
          filteredMonthTask = this.state.taskList.filter((x) =>
            x.Month == selectedMonth
          )
        }
        else if (selectedMonth && this.state.projectName) {
          filteredMonthTask = this.state.taskList.filter((x) =>
            x.Month == selectedMonth &&
            x.ProjectID === this.state.projectName
          )
        }
        else {
          filteredTaskListArr = [];
          let data = this.state.assigneeName;
          for (let i = 0; i < data.length; i++) {
            filteredTaskList = this.state.taskList.filter(
              (x) => x.AssigneeID === data[i].value &&
                x.Month == selectedMonth &&
                x.ProjectID === this.state.projectName
            );
            filteredTaskListArr.push(filteredTaskList);
          }
          let filteredTaskData = [].concat.apply([], filteredTaskListArr);
          this.setState({
            assigneeName: typeof data === "string" ? data.split(",") : data,
            filteredTaskList: filteredTaskData,
          });
        }
      }

      this.setState({
        filteredTaskList: filteredMonthTask,
      });
    }

  }


  getTask = async () => {
    Loader.show();
    try {
      let data = {
        UserID: CommonConfig.loginData().SecurityUserID,
      };
      await api
        .post(APIConstant.path.getTask, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ taskList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getAssigneeList = async () => {
    try {
      await api
        .post(APIConstant.path.getAssigneeList)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ assigneeList: response.data, });

            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Toast.error({ message: err });
          Loader.hide();
        });
    } catch (err) {
      Toast.error({ message: err });
      Loader.hide();
    }
  };

  getObserverList = async () => {
    try {
      await api
        .post(APIConstant.path.getObserverList)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ observerList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getProjectDropDown = async () => {
    try {
      let data = {
        UserID: CommonConfig.loginData().SecurityUserID,
      };
      await api
        .post(APIConstant.path.getProjectDropDown, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ projectList: response.data });
            let projectData = { value: "ALL", label: "ALL" };
            let data = res.data.length
              ? res.data.map((x) => {
                return { value: x.value, label: x.label };
              })
              : [];

            let projectDataList = [projectData, ...data];
            this.setState({ projectDataList: projectDataList });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getStatusDropDown = async () => {
    try {
      let data = {
        StringMapType: "STATUSTYPE",
      };
      await api
        .post(APIConstant.path.getStringMap, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ statusList: response.data, status: response.data.filter((x) => x.label == 'Done')[0]?.value });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  addUpdateTask = (e) => {
    e.preventDefault();
    if (this.validate()) {
      Loader.show();
      const {
        assignee,
        issueType,
        observer,
        taskSubject,
        project,
        assignedDate,
        dueDate,
        description,
        taskID,
        status,
        taskFile,
      } = this.state;
      var observerdata = [];
      for (let i = 0; i < observer.length; i++) {
        observerdata.push(observer[i].value);
      }
      try {
        let formData = new FormData();
        formData.append(
          "SecurityUserID",
          CommonConfig.loginData().SecurityUserID
        );
        formData.append("TaskID", taskID);
        formData.append("ObserverID", observerdata);
        formData.append("ObserverLen", observer.length);
        formData.append("AssigneeID", assignee);
        formData.append("IssueType", issueType);
        formData.append("ProjectID", project);
        formData.append("TaskSubject", taskSubject);
        formData.append("TaskStatus", status);
        formData.append("Description", description);

        formData.append(
          "AssignedDate",
          assignedDate ? moment(assignedDate).format("YYYY-MM-DD") : ""
        );
        formData.append(
          "DueDate",
          dueDate ? moment(dueDate).format("YYYY-MM-DD") : ""
        );
        // formData.append("TaskFile", taskFile);

        api
          .post(APIConstant.path.addUpdateTask, formData)
          .then((res) => {
            if (res.success) {
              this.setState({ visibleRight: false });
              this.handleClose('sidebar')
              Loader.hide();
              Toast.success({ message: res.message });
            } else {
              this.handleClose('sidebar')
              this.setState({ visibleRight: false });
              Loader.hide();
              Toast.error({ message: res.message });
            }
          })
          .catch((err) => {
            Loader.hide();
            Toast.error({ message: err });
          });
      } catch (err) {
        Loader.hide();
        Toast.error({ message: err });
      }
    }
  };

  getTaskDetailByID = (ID) => {
    Loader.show();

    try {
      let data = {
        TaskDetailID: ID,
      };
      api
        .post(APIConstant.path.getTaskDetailByID, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            var arryData = [];
            arryData = response.data[0].ObserverID.split(",");
            var filterdObserver = [];
            for (let i = 0; i < arryData.length; i++) {
              var observerData = this.state.observerList
                .filter((x) => x.value === arryData[i])
                .map((x) => {
                  return x;
                });
              filterdObserver.push(observerData);
            }
            let observerArray = [].concat.apply([], filterdObserver);
            this.setState({
              taskDataList: response.data[0],
              taskID: response.data[0].TaskMasterID,
              assignee: response.data[0].AssigneeID,
              observer: observerArray,
              issueType: response.data[0].IssueType,
              project: response.data[0].ProjectID,
              taskSubject: response.data[0].TaskSubject,
              assignedDate: new Date(response.data[0].AssignedDate),
              dueDate: new Date(response.data[0].Duedate),
              description: response.data[0].Description,
              status: response.data[0].TaskStatus,
            });
            Loader.hide();
            this.handleClose("editTask");
            this.getTask();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  deleteTask = () => {
    Loader.show();
    try {
      let data = {
        TaskID: this.state.TaskMasterID,
        SecurityUserID: CommonConfig.loginData().SecurityUserID,
      };
      api
        .post(APIConstant.path.deleteTask, data)
        .then((res) => {
          if (res.success) {
            this.handleClose("DialogBox");
            this.getTask();
            Loader.hide();
            Toast.success({ message: res.message });
          } else {
            this.handleClose("DialogBox");
            Loader.hide();
            Toast.error({ message: res.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getStringMap = async () => {
    try {
      let data = {
        StringMapType: "ISSUETYPE",
      };
      await api
        .post(APIConstant.path.getStringMap, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ issueTypeList: response.data, issueType: response.data.filter((x) => x.label == 'Task')[0]?.value });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  getTimeSheetType = async () => {
    try {
      let data = {
        StringMapType: "TIMESHEETTYPE",
      };
      await api
        .post(APIConstant.path.getStringMap, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ timeSheetTypeList: response.data });
            Loader.hide();
          } else {
            Loader.hide();
            Toast.error({ message: response.message });
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  handleOpen = (item, type) => {
    if (type === "sidebar") {
      this.setState({ visibleRight: true, openModel: true });
    } else if (type === "viewTask") {
      this.setState({ showModel: true });
      this.getTaskDetailByID(item.TaskMasterID);
    } else if (type === "editTask") {
      this.setState({ isEdit: true });
      this.setState({ openModel: true });
      this.getTaskDetailByID(item.TaskMasterID);
    } else if (type === "deleteCredit") {
      this.setState({ visibleRight: true, taskId: item.TaskDetailID });
    } else if (type === "timeSheet") {
      this.setState({ timeSheetModel: true });
    }
    else if (type === "FilterModel") {
      this.setState({ FilterModel: true });
    }
  };

  handleClose = (type) => {
    if (type === "sidebar") {
      this.setState({
        visibleRight: false,
        openModel: false,
        isEdit: false,
        taskSubject: "",
        taskSubjectErr: false,
        taskSubjectErrText: "",
        //       project: "",
        // projectErr: false,
        // projectErrText: "",

        // status: "",
        // statusErr: false,
        // statusErrText: "",

        // dueDate: "",
        // dueDateErr: false,
        // dueDateErrText: "",

        // assignedDate: "",
        // assignedDateErr: false,
        // assignedDateErrText: "",

        // observer: [],
        // observerErr: false,
        // observerErrText: "",

        // issueType: "",
        // issueTypeErr: false,
        // issueTypeErrText: "",

        // assignee: "",
        // assigneeErr: "",
        // assigneeErrText: "",

        description: "",
        descriptionErr: false,
        descriptionErrText: "",

      });
      // this.resetScreen();
    } else if (type === "viewTask") {
      this.setState({ showModel: false, TaskMasterID: "" });
      this.resetScreen();
    } else if (type === "deleteTask") {
      this.setState({ visibleRight: true });
      this.resetScreen();
    } else if (type === "DialogBox") {
      this.setState({
        openDialogBox: false,
      });
    } else if (type === "DialogBox") {
      this.setState({
        visibleRight: false,
        openModel: false,
      });
    } else if (type === "timeSheet") {
      this.setState({
        timeSheetModel: false,
        timeSheet: "",
        timeSheetDate: '',
        timeSheetType: "",
        sheetTypeName: "",
        showProjectList: false
      });
      this.resetScreen();
    }
  };

  actionBodyTemplate = () => {
    return [
      {
        field: "TaskID",
        headerClassName: "datatable-header",
        headerAlign: "left",
        headerName: "Task ID",
        width: 75,
      },
      {
        field: "ProjectName",
        headerClassName: "datatable-header",
        headerAlign: "left",
        headerName: "Project Name",
        width: 150,
      },
      {
        field: "TaskSubject",
        headerClassName: "datatable-header",
        headerAlign: "left",
        headerName: "Task Subject",
        width: 200,
      },
      {
        field: "AssigneeName",
        headerClassName: "datatable-header",
        headerAlign: "left",
        headerName: "Assignee",
        width: 150,
      },
      {
        field: "ObserverName",
        headerClassName: "datatable-header",
        headerAlign: "left",
        headerName: "Observer",
        width: 150,
      },
      {
        field: "AssignedDate",
        headerClassName: "datatable-header",
        headerAlign: "left",
        headerName: "Assigned Date",
        width: 120,
      },
      {
        field: "Duedate",
        headerClassName: "datatable-header",
        headerAlign: "left",
        headerName: "Due Date",
        width: 100,
      },
      {
        field: "Status",
        headerClassName: "datatable-header",
        headerAlign: "left",
        headerName: "Status",
        width: 80,
      },
      {
        field: "Actions",
        headerClassName: "datatable-header",
        headerAlign: "left",
        headerName: "Actions",
        type: "actions",
        width: 200,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            onClick={(e) =>
              this.handleOpen(
                { TaskMasterID: params.row.TaskMasterID },
                "viewTask"
              )
            }
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            // onClick={(e) => this.getTaskDetailByID(params.row.TaskMasterID)}
            onClick={(e) =>
              this.handleOpen(
                { TaskMasterID: params.row.TaskMasterID },
                "editTask"
              )
            }
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={(e) =>
              this.setState({
                openDialogBox: true,
                TaskMasterID: params.row.TaskMasterID,
              })
            }
          />,
        ],
      },
    ];
  };

  getTimeSheet = async (ID, date) => {
    Loader.show();
    let data = {
      UserID: ID ? ID :
        CommonConfig.loginData().SecurityUserID,
      Month: dayjs(date).format("YYYY-MM-DD")
    };
    try {
      await api
        .post(APIConstant.path.getTimeSheet, data)
        .then(async (res) => {

          let response = await res;
          if (response.success) {
            this.setState({ timeSheetList: response.data });
            this.setState({ timeSheetFileName: response.data[0].DeveloperName ? response.data[0].DeveloperName : CommonConfig.loginData().Name })
            Loader.hide();
          } else {
            Toast.error({ message: response.message });
            Loader.hide();
          }
        })
        .catch((err) => { });
    } catch (err) {
      Toast.error({ message: err });
      Loader.hide();
    }
  };

  getClientTimeSheet = async (date) => {
    Loader.show();
    try {
      let data = {
        ProjectID: this.state.timeSheet,
        Month: dayjs(date).format("YYYY-MM-DD")
        // date ? moment(date).format("YYYY-MM-DD") : '',
      };
      await api
        .post(APIConstant.path.getClientTimeSheet, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            this.setState({ timeSheetList: response.data });
            Loader.hide();
          } else {
            Toast.error({ message: response.message });
            Loader.hide();
          }
        })
        .catch((err) => { });
    } catch (err) {
      Toast.error({ message: err });
      Loader.hide();
    }
  };

  getMonthYear = () => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const date = new Date(this.state.timeSheetDate);
    return (monthNames[date.getMonth()] + '-' + date.getFullYear());
  }

  render() {
    const {
      monthTask,
      openDialogBox,
      taskSubject,
      taskSubjectErr,
      taskSubjectErrText,
      assignedDate,
      assignedDateErr,
      assignedDateErrText,
      assignee,
      assigneeErr,
      assigneeErrText,
      issueType,
      issueTypeErr,
      issueTypeErrText,
      observer,
      observerErr,
      observerErrText,
      project,
      projectErr,
      projectErrText,
      status,
      statusErr,
      statusErrText,
      filteredTaskList,
      dueDate,
      dueDateErr,
      dueDateErrText,
      description,
      descriptionErr,
      descriptionErrText,
      taskList,
      observerList,
      projectList,
      issueTypeList,
      assigneeList,
      statusList,
      showModel,
      taskDataList,
      isEdit,
      assigneeName,
      startDate,
      filterStatus,
      role,
      timeSheet,
      timeSheetErr,
      timeSheetErrText,
      timeSheetType,
      timeSheetTypeErr,
      timeSheetTypeErrText,
      timeSheetModel,
      timeSheetTypeList,
      showProjectList,
      projectName,
      timeSheetDate,
      timeSheetAssignee,
      timeSheetAssigneeErr,
      timeSheetAssigneeErrText,
      projectDataList,
      modules,
      formats,
      ClientTimeSheetName,
      timeSheetFileName
    } = this.state;

    let filteredArry = [];
    if (
      CommonConfig.isObjectEmpty(assigneeName) &&
      CommonConfig.isEmpty(filterStatus) &&
      CommonConfig.isEmpty(startDate) &&
      CommonConfig.isObjectEmpty(projectName) &&
      !CommonConfig.isObjectEmpty(monthTask)
      // filteredTaskList.length
    ) {
      filteredArry = taskList;
    }
    else {
      filteredArry = filteredTaskList;
    }

    return (
      <section>
        <div className="main-heading">
          <div className="page-title">
            <h1>Tasks</h1>
          </div>
          <div className="action-buttons">
            <button type="button" className="filter-button" onClick={(e) => this.handleOpen(e, "FilterModel")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={14}
                height={15}
                viewBox="0 0 14 15"
                fill="none"
              >
                <path
                  d="M2.33626 0.901001H11.1363C11.8696 0.901001 12.4696 1.501 12.4696 2.23433V3.701C12.4696 4.23433 12.1363 4.901 11.8029 5.23433L8.93627 7.76767C8.53627 8.101 8.2696 8.76767 8.2696 9.301V12.1677C8.2696 12.5677 8.00294 13.101 7.6696 13.301L6.73627 13.901C5.8696 14.4343 4.6696 13.8343 4.6696 12.7677V9.23433C4.6696 8.76767 4.40293 8.16767 4.13626 7.83433L1.60293 5.16767C1.2696 4.83433 1.00293 4.23433 1.00293 3.83433V2.301C1.00293 1.501 1.60293 0.901001 2.33626 0.901001Z"
                  stroke="#8B8B8B"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              <span>Filters</span>
            </button>
            <button type="button" className="add-button" onClick={(e) => this.handleOpen(e, "timeSheet")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={14}
                height={15}
                viewBox="0 0 14 15"
                fill="none"
              >
                <path
                  d="M13.2222 8.27785H0.777778C0.352593 8.27785 0 7.92525 0 7.50007C0 7.07488 0.352593 6.72229 0.777778 6.72229H13.2222C13.6474 6.72229 14 7.07488 14 7.50007C14 7.92525 13.6474 8.27785 13.2222 8.27785Z"
                  fill="white"
                />
                <path
                  d="M7.00043 14.5C6.57525 14.5 6.22266 14.1474 6.22266 13.7222V1.27778C6.22266 0.852593 6.57525 0.5 7.00043 0.5C7.42562 0.5 7.77821 0.852593 7.77821 1.27778V13.7222C7.77821 14.1474 7.42562 14.5 7.00043 14.5Z"
                  fill="white"
                />
              </svg>{" "}
              <span>Timesheet</span>
            </button>
            <button type="button" className="add-button" onClick={(e) => this.handleOpen(e, "sidebar")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={14}
                height={15}
                viewBox="0 0 14 15"
                fill="none"
              >
                <path
                  d="M13.2222 8.27785H0.777778C0.352593 8.27785 0 7.92525 0 7.50007C0 7.07488 0.352593 6.72229 0.777778 6.72229H13.2222C13.6474 6.72229 14 7.07488 14 7.50007C14 7.92525 13.6474 8.27785 13.2222 8.27785Z"
                  fill="white"
                />
                <path
                  d="M7.00043 14.5C6.57525 14.5 6.22266 14.1474 6.22266 13.7222V1.27778C6.22266 0.852593 6.57525 0.5 7.00043 0.5C7.42562 0.5 7.77821 0.852593 7.77821 1.27778V13.7222C7.77821 14.1474 7.42562 14.5 7.00043 14.5Z"
                  fill="white"
                />
              </svg>{" "}
              <span>Add Task</span>
            </button>
          </div>
          <div>
            <button className="add-button" onClick={(e) => this.handleOpen(e, "timeSheet")}>TimeSheet</button>
          </div>
        </div>
        <hr />
        <div className="table">
          <DataGrid
            sx={{
              // boxShadow: 2,
              border: "none",
            }}
            rows={filteredArry}
            columns={this.actionBodyTemplate()}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.TaskMasterID}
            hideFooterSelectedRowCount
            rowHeight={42}
          />
        </div>

        <Drawer
          className="main-drawer-container"
          visible={this.state.visibleRight}
          open={this.state.openModel}
          anchor="right"
          PaperProps={{
            sx: { width: "50%" },
          }}
        >
          <div className="main-drawer-area">
            <div className="drawer-header">
              <div> {isEdit ? "Edit Task" : "Add Task"}</div>

              <div
                className="close-btn"
                onClick={(e) => this.handleClose("sidebar")}
              >
                {"X"}
                <i className="icon-close"></i>
              </div>
            </div>
            <div className="drawer-body">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <TextField
                      variant="outlined"
                      type="taskSubject"
                      name="taskSubject"
                      value={taskSubject}
                      onChange={(e) => this.handleChange(e, "taskSubject")}
                      label="Task Subject"
                    />
                    {taskSubjectErr
                      ? CommonConfig.showErrorMsg(taskSubjectErrText)
                      : null}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-chip-label">
                        Observer
                      </InputLabel>
                      <Select
                        label="Observer"
                        multiple
                        value={observer}
                        onChange={(e) => this.handleChange(e, "observer")}
                        renderValue={(selected) => (
                          <>
                            {selected.map((x, idx) => (
                              <Chip key={idx} label={x.label} />
                            ))}
                          </>
                        )}
                      >
                        {observerList.map((name, idx) => (
                          <MenuItem key={idx} value={name}>
                            {name.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {observerErr
                        ? CommonConfig.showErrorMsg(observerErrText)
                        : null}
                    </FormControl>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            inputFormat="dd/MM/yyyy"
                            value={
                              assignedDate
                                ? assignedDate
                                : this.props.value || null
                            }
                            onChange={(e) =>
                              this.handleChange(e, "assignedDate")
                            }
                            renderInput={(params) => <TextField {...params} />}
                            label="Assigned Date"
                          />
                        </Stack>
                        {assignedDateErr
                          ? CommonConfig.showErrorMsg(assignedDateErrText)
                          : null}
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          inputFormat="dd/MM/yyyy"
                          value={dueDate ? dueDate : this.props.value || null}
                          minDate={new Date(assignedDate)}
                          onChange={(e) => this.handleChange(e, "dueDate")}
                          renderInput={(params) => <TextField {...params} />}
                          label="Due Date"
                        />
                      </Stack>
                      {dueDateErr
                        ? CommonConfig.showErrorMsg(dueDateErrText)
                        : null}
                    </LocalizationProvider>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <InputLabel id="Project">Project</InputLabel>
                      <Select
                        value={project}
                        onChange={(e) => this.handleChange(e, "project")}
                        label="Project"
                      >
                        {projectList.map((p) => (
                          <MenuItem key={p.name + p.value} value={p.value}>
                            {p.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {projectErr
                      ? CommonConfig.showErrorMsg(projectErrText)
                      : null}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <InputLabel id="IssueType">Issue Type</InputLabel>
                      <Select
                        value={issueType}
                        onChange={(e) => this.handleChange(e, "issueType")}
                        label="IssueType"
                      >
                        {issueTypeList.map((p) => (
                          <MenuItem key={p.name + p.value} value={p.value}>
                            {p.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {issueTypeErr
                      ? CommonConfig.showErrorMsg(issueTypeErrText)
                      : null}
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <InputLabel id="Assignee">Assignee</InputLabel>
                      <Select
                        value={assignee}
                        onChange={(e) => this.handleChange(e, "assignee")}
                        label="Assignee"
                      >
                        {assigneeList.map((p) => (
                          <MenuItem key={p.name + p.value} value={p.value}>
                            {p.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {assigneeErr
                      ? CommonConfig.showErrorMsg(assigneeErrText)
                      : null}
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <InputLabel id="Status">Status</InputLabel>
                      <Select
                        value={status}
                        onChange={(e) => this.handleChange(e, "status")}
                        label="Status"
                      >
                        {statusList.map((p) => (
                          <MenuItem key={p.nameformat + p.value} value={p.value}>
                            {p.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {statusErr
                      ? CommonConfig.showErrorMsg(statusErrText)
                      : null}
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="form-item">
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      value={description}
                      onChange={(e) => this.handleChange(e, "description")}
                    ></ReactQuill>
                    {descriptionErr
                      ? CommonConfig.showErrorMsg(descriptionErrText)
                      : null}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="drawer-footer">
              <div
                className="secondary-btn"
                onClick={(e) => this.handleClose("sidebar")}
              >
                Cancel
              </div>
              <div
                className="primary-btn"
                onClick={(e) => this.addUpdateTask(e)}
              >
                Submit
              </div>
            </div>
          </div>
        </Drawer>
        <Dialog
          open={openDialogBox}
          onClose={(e) => this.handleClose("DialogBox")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h4 className="dialog-title">{"Delete Task"}</h4>
            {"Are you sure you want to delete this task?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={(e) => this.handleClose("DialogBox")}>
              Cancel
            </Button>
            <Button onClick={(e) => this.deleteTask()}>Delete</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showModel}
          onClose={(e) => this.handleClose("viewTask")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="main-dialog-area"
        >
          <div className="dialog-header">
            <h3 className="dialog-title">{"Task Details"}</h3>
            <IconButton
              className="dialog-close"
              aria-label="close"
              onClick={(e) => this.handleClose("viewTask")}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-content">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Task Name:</div>
                  <div className="info-value">
                    {taskDataList &&
                      taskDataList.TaskSubject !== "" &&
                      taskDataList.TaskSubject !== null
                      ? taskDataList.TaskSubject
                      : "N/A"}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Assigned Date:</div>
                  <div className="info-value">
                    {taskDataList &&
                      taskDataList.AssignedDateName !== "" &&
                      taskDataList.AssignedDateName !== null
                      ? taskDataList.AssignedDateName
                      : "N/A"}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Due Date:</div>
                  <div className="info-value">
                    {taskDataList &&
                      taskDataList.DuedateName !== "" &&
                      taskDataList.DuedateName !== null
                      ? taskDataList.DuedateName
                      : "N/A"}
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Issue Type:</div>
                  <div className="info-value">
                    {taskDataList &&
                      taskDataList.IssueTypeName !== "" &&
                      taskDataList.IssueTypeName !== null
                      ? taskDataList.IssueTypeName
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Observer:</div>
                  <div className="info-value">
                    {taskDataList &&
                      taskDataList.ObserverName !== "" &&
                      taskDataList.ObserverName !== null
                      ? taskDataList.ObserverName
                      : "N/A"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="dialog-item">
                  <div className="info-label">Task Status:</div>
                  <div className="info-value">
                    {taskDataList &&
                      taskDataList.TaskStatusName !== "" &&
                      taskDataList.TaskStatusName !== null
                      ? taskDataList.TaskStatusName
                      : "N/A"}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className="dialog-item">
                  <div className="info-label">Task Description:</div>
                  <div className="info-value">
                    {taskDataList &&
                      taskDataList.Description !== "" &&
                      taskDataList.Description !== null
                      ? ReactHtmlParser(taskDataList.Description)
                      : "N/A"}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Dialog>

        <Dialog
          open={timeSheetModel}
          onClose={(e) => this.handleClose("DialogBox")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="main-dialog-area"
        >
          <div className="dialog-content"></div>
          <div className="dialog-header">
            <h3 className="dialog-title">{"Download Timesheet"}</h3>
            <IconButton
              className="dialog-close"
              aria-label="close"
              onClick={(e) => this.handleClose("timeSheet")}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-content">
            {role === "Project Manager" || role === "CEO" ? (
              <div>
                <div>
                  <div className="form-item">
                    <FormControl fullWidth>
                      <InputLabel id="Project">Select Timesheet</InputLabel>
                      <Select
                        value={timeSheetType}
                        onChange={(e) => this.handleChange(e, "timeSheetType")}
                        label="Project"
                        variant="standard"
                      >
                        {timeSheetTypeList.map((p) => (
                          <MenuItem key={p.name + p.value} value={p.value}>
                            {p.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {timeSheetTypeErr
                      ? CommonConfig.showErrorMsg(timeSheetTypeErrText)
                      : null}
                  </div>

                </div>

              </div>
            ) : (
              ''
            )}
            {showProjectList ? (
              <div>
                <div className="form-item mt-3">
                  <FormControl fullWidth>
                    <InputLabel id="Project">Select Project</InputLabel>
                    <Select
                      value={timeSheet}
                      onChange={(e) => this.handleChange(e, "timeSheetProject")}
                      label="Project"
                      variant="standard"
                    >
                      {projectList.map((p) => (
                        <MenuItem key={p.name + p.value} value={p.value}>
                          {p.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {timeSheetErr
                    ? CommonConfig.showErrorMsg(timeSheetErrText)
                    : null}
                </div>

              </div>
            ) : (
              ""
            )}
            <div>
              {role === "Project Manager" || role === "CEO" ? (
                <div>
                  {!showProjectList ? (
                    <div>
                      <Grid item xs={12} md={6}>
                        <div className="form-item">
                          <FormControl fullWidth>
                            <InputLabel id="Assignee">Select Assignee</InputLabel>
                            <Select
                              value={timeSheetAssignee}
                              onChange={(e) =>
                                this.handleChange(e, "timeSheetAssignee")
                              }
                              label="AssigneeName"
                            >
                              {assigneeList.map((p) => (
                                <MenuItem key={p.name + p.value} value={p.value}>
                                  {p.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {timeSheetAssigneeErr
                            ? CommonConfig.showErrorMsg(timeSheetAssigneeErrText)
                            : null}
                        </div>
                      </Grid>

                    </div>
                  ) : ("")}
                </div>

              ) : ("")}
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker

                    // views={["month"]}
                    label="Select Month"
                    // minDate={new Date("2012-03-01")}
                    // maxDate={new Date("2028-01-01")}
                    value={
                      timeSheetDate ? timeSheetDate : this.props.value || null
                    }
                    onChange={(e) => this.handleChange(e, "timeSheetDate")}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </div>

          </div>
          <DialogActions>
            <div onClick={(e) => this.handleClose("timeSheet")}>

              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="primary-main-btn mb-1"
                table="table-to-xls"
                filename={ClientTimeSheetName ? ClientTimeSheetName + '-' + this.getMonthYear() : timeSheetFileName + '-' + this.getMonthYear()}
                sheet="tablexls"
                buttonText="Export to Excel" />
            </div>
          </DialogActions>
        </Dialog>

        <div className="d-none">
          <table id="table-to-xls" cellspacing="10" cellpadding="10">
            <thead>
              <tr>
                <th align="left" bgcolor="#81b751"><font size="+2" color="#ffffff">Task ID</font></th>
                <th align="left" bgcolor="#81b751"><font size="+2" color="#ffffff">Project Name</font></th>
                <th align="left" bgcolor="#81b751"><font size="+2" color="#ffffff">Task Subject</font></th>
                <th align="left" bgcolor="#81b751"><font size="+2" color="#ffffff">Developer Name</font></th>
                <th align="left" bgcolor="#81b751"><font size="+2" color="#ffffff">Submit Date</font></th>
                <th align="left" bgcolor="#81b751"><font size="+2" color="#ffffff">Status</font></th>
                <th align="left" bgcolor="#81b751"><font size="+2" color="#ffffff">Comments</font></th>
              </tr>
            </thead>
            <tbody>
              {this.state.timeSheetList.length ?
                this.state.timeSheetList.map(x => {
                  return (
                    <>
                      {x.IsEmptyRow == 0 && x.IsWeekend == 0 && x.IsGreyRow == 0 ?
                        <tr>
                          <td height="30" width="100"><p><font size="+1">{x.TaskID}</font></p></td>
                          <td height="30" width="150"><p><font size="+1">{x.ProjectName}</font></p></td>
                          <td height="30" width="400"><p><font size="+1">{x.TaskSubject}</font></p></td>
                          <td height="30" width="200"><p><font size="+1">{x.DeveloperName}</font></p></td>
                          <td height="30" width="150"><p><font size="+1">{x.AssignedDate}</font></p></td>
                          <td height="30" width="150"><p><font size="+1">{x.Status}</font></p></td>
                          <td height="30" width="500"><p><font size="+1">{ReactHtmlParser(x.Description)}</font></p></td>
                        </tr>
                        :
                        x.IsEmptyRow != 0 ?
                          <tr className='empty-row'>
                            <td height="30"><p><font size="+1">&nbsp;</font></p></td>
                          </tr>
                          :
                          x.IsGreyRow != 0 ?
                            <tr>
                              {x.Description ?
                                <td colspan="7" bgcolor="#D9D9D9" align="center">
                                  <p height="30"><font size="+1" color="#0563c1"> <b>{x.Description}</b></font></p>
                                </td>
                                :
                                <td height="30" colspan="7" bgcolor="#D9D9D9" align="center"><p><font size="+1" color="#D9D9D9">.</font></p></td>
                              }
                            </tr>
                            :
                            null
                      }
                    </>
                  )
                })
                : null
              }
            </tbody>
          </table>
        </div>
      </section>

    );
  }
}
export default Task;
